

export const updateEthAccountBalances = async (selectedWalletAddress, contracts) => {
    let balances = {};
    const srk = await contracts.srkToken.balanceOf(selectedWalletAddress, true);
    const uniV2 = await contracts.uniV2Token.balanceOf(selectedWalletAddress, true);

    balances.srk = parseFloat(srk.toSignificant(15));
    balances.uniV2 = parseFloat(uniV2.toSignificant(15));

    const usdt = await contracts.usdtToken.balanceOf(selectedWalletAddress, true);
    const sUsdtUniV2 = await contracts.srkUsdtUniV2Token.balanceOf(selectedWalletAddress, true);

    balances.usdt = parseFloat(usdt.toSignificant(15));
    balances.sUsdtUniV2 = parseFloat(sUsdtUniV2.toSignificant(15));

    return balances;
};
