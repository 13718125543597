import {withRouter} from 'react-router-dom';
import PoolActionsPage from "../PoolActionsPage";

class KgoSrkbPool extends PoolActionsPage {
    constructor(props) {
        super(props);
        this.poolData = props.staking['kgoSrkbLPool' + props.match.params.version];
        this.stakeTokenBal = props.wallet.balance.kgo;
        this.rewardTokenBal = props.wallet.balance.srkb;
        this.stakeTokenContract = props.contracts.kgoToken;
        this.stakingRewardsContract = props.contracts.kgoSrkbLaunchPoolV1;
    }
}

export default withRouter(KgoSrkbPool);
