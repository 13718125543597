import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class AirxBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.sfuel;
        this.lpTokenBal = props.wallet.balance.airxBnbSparkLP;

        this.lpTokenContract = props.contracts.airxBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['airxBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['airxBnbPool' + props.match.params.version];
    }
}

export default withRouter(AirxBnbStakeActionsPage);
