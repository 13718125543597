import {REACT_APP_PANCAKEAPP_URL, SFUEL} from '../../../../constants';
import PoolBasedStakingV1 from "../PoolBasedStakingV1";
import { sfuelsfuelpool } from "../../../../apollo/client";

class SfuelSfuelPool extends PoolBasedStakingV1 {
    initialized = false;

    constructor(contractData, stakeTokenInstance) {
        super(contractData, {
            stakeTokenInstance: stakeTokenInstance,
            rewardsToken: SFUEL,
            stakingToken: SFUEL,
            pairToken: 'ETH',
            dexUrl: REACT_APP_PANCAKEAPP_URL,
            rewardTokenDexUrl: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x37ac4d6140e54304d77437a5c11924f61a2d976f',
            graphQL: sfuelsfuelpool
        });
    }
}

export default SfuelSfuelPool;
