import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class SrkbBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.srkb;
        this.lpTokenBal = props.wallet.balance.srkbBnbSparkLP;
        this.lpTokenContract = props.contracts.srkbBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['srkbBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['srkbBnbPool' + props.match.params.version];
    }
}

export default withRouter(SrkbBnbStakeActionsPage);
