import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class ChimomBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.chimom;
        this.lpTokenBal = props.wallet.balance.chimomBnbSparkLP;

        this.lpTokenContract = props.contracts.chimomBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['chimomBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['chimomBnbPool' + props.match.params.version];
    }
}

export default withRouter(ChimomBnbStakeActionsPage);
