import React from 'react';

const RoadmapPage = () => {
  return (
    <div className="roadmap-page">
      <h1>Roadmap</h1>
    </div>
  );
}

export default RoadmapPage;
