import React, {Component} from "react";
import {HashRouter} from "react-router-dom";
import Router from "./Router";
import '../../scss/app.scss';
import Web3Instance from "../../blockchain/Web3Instance";

import {BASENAME} from "../../blockchain/constants";
import {BINANCE_CHAIN_ID, BINANCE_CHAIN_TESTNET_ID, BINANCE_SMART_CHAIN} from "../../blockchain/bsc/constants";
import {ChainId} from "@uniswap/sdk";
import {initializeContracts} from "../../blockchain/contracts";
import {updateAccountBalances} from "../../blockchain/tokenBalances";
import {updateStakingBalances} from "../../blockchain/stakingDetails";
import {ETH_LP_STAKING} from "../../blockchain/eth/staking/constants";
import {BSC_TOKEN} from "../../blockchain/bsc/tokens/constants";
import {ETH_TOKEN} from "../../blockchain/eth/tokens/constants";
import {BSC_POOL_STAKING} from "../../blockchain/bsc/staking/pool/stakingDetails";
import {BSC_LP_STAKING} from "../../blockchain/bsc/staking/liquidity/stakingDetails";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockchain: 'ethereum',
            toggleConnectDialog: false,
            web3Obj: null,
            ethereum: null,
            wallet: {
                connected: false,
                connecting: false,
                address: null,
                ethSymbol: 'ETH',
                balance: {
                    ...ETH_TOKEN.list,
                    ...BSC_TOKEN.list,
                },
                network: 'n/a',
                chain_id: ChainId.MAINNET,
                networkColor: 'gray',
            },
            staking: {
                ...ETH_LP_STAKING.list,
                ...BSC_LP_STAKING.list,
                ...BSC_POOL_STAKING.list
            },
            contracts: {
                ...ETH_TOKEN.contracts,
                ...BSC_TOKEN.contracts,
                ...ETH_LP_STAKING.contracts,
                ...BSC_LP_STAKING.contracts,
                ...BSC_POOL_STAKING.contracts,
            },
            onRightNetwork: true,
        };

        this.newStateData = this.state;
    }

    componentDidMount = () => {
        this.initiateWalletConnection();
    };

    updateState() {
        this.setState(this.newStateData);
    }

    initiateWalletConnection = () => {
        // wait for browser to inject web3 or window.eth data before trying to connect
        setTimeout(() => {
            Web3Instance.checkIfConnected().then(async (hasSelectedAddress) => {
                this.ethereum = this.newStateData.ethereum = await Web3Instance.getEthereum();
                // alert(this.ethereum);
                this.newStateData.web3Obj = await Web3Instance.getRawWeb3();
                this.listenToEthereum();

                await this.setBlockchain();
                await this.initContracts()

                if (hasSelectedAddress) {
                    await this.connectToWallet();
                }
                this.updateState();
            }).catch(e => {
                this.isOnRightNetwork(false);
                console.error(e.message);
            });
        }, 1000);
    };

    setBlockchain = async () => {
        const chainId = await Web3Instance.getChainID();
        switch (chainId) {
            case BINANCE_CHAIN_ID:
            case BINANCE_CHAIN_TESTNET_ID:
                this.blockchain = this.newStateData.blockchain = BINANCE_SMART_CHAIN
                this.newStateData.wallet.ethSymbol = 'BNB';
                break;
            default:

        }
        this.newStateData.wallet.network = await Web3Instance.getNetworkType();
        this.newStateData.wallet.chain_id = await Web3Instance.getChainID();
        this.newStateData.wallet.networkColor = await Web3Instance.getNetworkColor();
    };

    connectToWallet = async () => {
        Web3Instance.requestAccounts().then(async (r) => {
            await this.updateAccountInfo();
            this.isOnRightNetwork(true);
            this.updateState();
        }).catch(e => console.log)
    };

    initContracts = async () => {
        this.newStateData.contracts = await initializeContracts(this.blockchain);
    };

    updateAccountInfo = async (updateState = false) => {
        const contracts = this.state.contracts ?? this.newStateData.contracts;
        this.selectedAddress = await Web3Instance.getSelectedAddress();
        this.newStateData.wallet.connected = true;
        this.newStateData.wallet.address = this.selectedAddress;
        this.newStateData.wallet.maskedAddress = this.selectedAddress.substr(0, 6) + '...' + this.selectedAddress.substr(-4, 6);

        this.newStateData.wallet.balance =
            await updateAccountBalances(this.blockchain, contracts, this.selectedAddress);
        if (updateState) {
            this.updateState();
        }
        await this.updateStakingInfo(updateState);
    };

    updateStakingInfo = async (updateState) => {
        const contracts = this.state.contracts ?? this.newStateData.contracts;
        this.newStateData.staking =
            await updateStakingBalances(this.blockchain, contracts, this.selectedAddress);

        if (updateState) {
            this.updateState();
        }
    };

    listenToEthereum = () => {
        this.ethereum.on('accountsChanged', async (accounts) => {
            // Time to reload your interface with accounts[0]!
            if (accounts.length !== 0) {
                await this.updateAccountInfo(true);
            }
        });

        //see https://docs.metamask.io/guide/ethereum-provider.html#chain-ids
        //for important information about the MetaMask provider's chain IDs.
        this.ethereum.on('chainChanged', async () => {
            document.location.reload();
        });
    };

    isOnRightNetwork = status => {
        this.newStateData.onRightNetwork = status;
    };

    render() {
        return (
            <HashRouter basename={BASENAME}>
                <div className="app">
                    <Router {...this.state}
                            connectToWallet={this.connectToWallet}
                            disconnectWallet={this.disconnectWallet}
                            updateAccountInfo={this.updateAccountInfo}
                            updateStakingInfo={this.updateStakingInfo}
                    />
                </div>
            </HashRouter>
        );
    }
}

export default App;
