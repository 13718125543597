import {withRouter} from 'react-router-dom';
import PoolActionsPage from "../PoolActionsPage";

class SfuelSfuelPool extends PoolActionsPage {
    constructor(props) {
        super(props);
        this.poolData = props.staking['sfuelSfuelLPool' + props.match.params.version];
        this.stakeTokenBal = props.wallet.balance.sfuel;
        this.rewardTokenBal = props.wallet.balance.sfuel;
        this.stakeTokenContract = props.contracts.sfuelToken;
        this.stakingRewardsContract = props.contracts.sfuelSfuelLaunchPoolV1;
    }
}

export default withRouter(SfuelSfuelPool);
