import React from 'react';

const Heading = (
    {
        heading = 'Heading',
        headingSize = 'heading-1',
        headingColor = '#fff',
        subheading = '',
        subheadingSize = 'subheading-1',
        subheadingColor = '#9e9e9e',
        token1 = '',
        token2 = ''
    }
) => (
    <div className="heading-container">
        <div className={`heading-title justify-content-center ${headingSize}`} style={{color: headingColor}}>
            {token1 && token2 ?
                <div className=''>
                    <img alt={token1.altText} src={token1.img} style={{width: '2em'}} className="img mr-2 mb-2"/>
                    <img alt={token2.altText} src={token2.img} style={{width: '2em'}} className="img mb-2"/>
                </div>
                : ''}
            {heading}
        </div>
        {
            subheading !== '' &&
            <p className={`mt-3 subheading-text ${subheadingSize}`} style={{color: subheadingColor}}>
                {subheading}
            </p>
        }
    </div>
);

export default Heading;
