import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class SfuelBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.sfuel;
        this.lpTokenBal = props.wallet.balance.sfuelBnbCakeLP;

        this.lpTokenContract = props.contracts.sfuelBnbCakeLpToken;
        this.stakingRewardsContract = props.contracts.sfuelBnbStakingRewards;

        this.poolData = props.staking.sfuelBnbPool;
    }
}

export default withRouter(SfuelBnbStakeActionsPage);
