import {withRouter} from 'react-router-dom';
import StakeActionsPage from "../StakeActionsPage";
import {Col, Row} from "react-bootstrap";
import Box from "../../../components/Box";
import numeral from "numeral";
import CustomButton from "../../../components/Button";
import {showFullBalance} from "../../../blockchain/helpers";
import React from "react";
import {LP_PAIR_INFO} from "../../../blockchain/constants";

class SrkUsdtActionsPage extends StakeActionsPage {
    constructor(props) {
        super(props);
        this.token1Bal = props.wallet.balance.usdt;
        this.rewardsTokenBal = props.wallet.balance.srk;
        this.lpTokenBal = props.wallet.balance.sUsdtUniV2;

        this.stakingData = LP_PAIR_INFO['SRK-USDT-V1'];
        this.lpTokenContract = props.contracts.srkUsdtUniV2Token;
        this.stakingRewardsContract = props.contracts.srkUsdtStakingRewards;

        this.poolData = props.staking.srkUsdtPool;
    }

    renderBalanceInfo() {
        return <>
            <Col xs={12} md={12} lg={6}>
                <Row>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">{this.stakingData.TOKEN2} Tokens</div>
                            <div className="liquidity-amount text-center">
                                {numeral(this.rewardsTokenBal).format('0,0.0000')}
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={'get-reward-btn'}
                                    text={`ADD MORE`}
                                    handleClick={() => this.openUniSwapUrl('swap')}
                                    className='btn btn-secondary btn-block blue btn-sm ml-2 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div
                                className="liquidity-title mb-3 text-uppercase">{this.stakingData.TOKEN1} Tokens
                            </div>
                            <div className="liquidity-amount text-center">
                                {numeral(this.token1Bal).format('0,0.0000')}
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={'get-usdt-btn'}
                                    text={`ADD MORE`}
                                    handleClick={() => this.openUniSwapUrl('swap')}
                                    className='btn btn-secondary btn-block blue btn-sm ml-2 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">{this.stakingData.STAKE_TYPE} Tokens
                            </div>
                            <div
                                className="liquidity-amount text-center line-height-1">
                                                    <span
                                                        className={'m-0 p-0 d-none d-lg-block'}>{numeral(this.lpTokenBal).format('0,0.0000', Math.floor)}</span>
                                <span
                                    className={'m-0 p-0 d-block d-lg-none'}>{this.lpTokenBal}</span>
                                <small
                                    className={'mt-1 small-full-balance m-0 p-0 text-muted d-none d-lg-block'
                                    + (showFullBalance(this.lpTokenBal) ? '' : 'd-none')}
                                >
                                    ({this.lpTokenBal})
                                </small>
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={`get-${this.stakingData.STAKE_TYPE}-btn`}
                                    text={`ADD LIQUIDITY`}
                                    handleClick={() => this.openUniSwapUrl('add')}
                                    className='btn btn-secondary blue btn-sm ml-2 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                    </Col>
                </Row>
            </Col>
        </>;
    }
}

export default withRouter(SrkUsdtActionsPage);

