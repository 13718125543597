import React from 'react';
import { Modal, Button, Spinner, Row, Col } from 'react-bootstrap';

const ModalDialog = props => {

  const {
    show, onHide, confirmation, buttontitle, children, isWaiting, iscomplete, headertitle, size='md'
  } = props;

  let buttonTitle = buttontitle && !iscomplete ? buttontitle : 'Close';

  const handleConfirm = () => {
    if (!iscomplete && confirmation) {
      confirmation();
    } else {
      onHide();
    }
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="modal-header" style={{borderBottom: 0}}>
        <h5>{headertitle}</h5>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {
          isWaiting &&
          <Row className="p-2 m-2">
            <Col xs={12} className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" style={{width: '150px', height: '150px'}} />
            </Col>
          </Row>
        }
        <Row className="p-0 m-0">
          <Col xs={12} className="d-flex justify-content-center">
            {children}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{borderTop: 0}}>
        <div style={{width: '100%'}}>
          {
            !isWaiting &&
            <Button
              block
              style={{backgroundColor: '#3cbbe8', border: 0, fontWeight: 600, fontFamily: 'sans-serif'}}
              onClick={handleConfirm}>
              {buttonTitle}
            </Button>
          }
        </div>
      </Modal.Footer>

    </Modal>
  );
}

export default ModalDialog;
