import {ChainId} from "@uniswap/sdk";
import {initToken} from "../../helpers";
import {SRK_ABI, SRK_ETH_UNI_ABI, SRK_USDT_UNI_ABI, USDT_ABI} from "./abis";

export const ETHEREUM_CHAIN = 'Ethereum';

export const SRK = {
    [ChainId.MAINNET]: initToken(ChainId.MAINNET, SRK_ABI),
    [ChainId.RINKEBY]: initToken(ChainId.RINKEBY, SRK_ABI),
    [ChainId.ROPSTEN]: initToken(ChainId.ROPSTEN, SRK_ABI),
};

export const USDT = {
    [ChainId.MAINNET]: initToken(ChainId.MAINNET, USDT_ABI),
    [ChainId.RINKEBY]: initToken(ChainId.RINKEBY, USDT_ABI),
};

export const SRKETHUNIV2 = {
    [ChainId.MAINNET]: initToken(ChainId.MAINNET, SRK_ETH_UNI_ABI),
    [ChainId.RINKEBY]: initToken(ChainId.RINKEBY, SRK_ETH_UNI_ABI),
    [ChainId.ROPSTEN]: initToken(ChainId.ROPSTEN, SRK_ETH_UNI_ABI),
};

export const SRKUSDTUNIV2 = {
    [ChainId.MAINNET]: initToken(ChainId.MAINNET, SRK_USDT_UNI_ABI),
};

export const ETH_TOKEN = {
    list: {
        eth: '0.00000',
        srk: '0.00000',
        uniV2: '0.00000',
        sUsdtUniV2: '0.00000',
    },
    contracts: {
        srkToken: null,
        uniV2Token: null,
        usdt: null,
        srkUsdtUniV2Token: null,
    }
}
