import {BINANCE_CHAIN_ID} from "../../constants";

export const STAKING_PAIR_INFO = {
    "SRKb-SFUEL-V1": {
        TITLE: "Stake SRKb and Earn SFUEL",
        TOKEN1: 'SRKb',
        TOKEN2: 'SFUEL',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SFUEL-SFUEL-V1": {
        TITLE: "Stake SFUEL and Earn SFUEL",
        TOKEN1: 'SFUEL',
        TOKEN2: 'SFUEL',
        STAKE_TYPE: "SFUEL",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-SRKb-V1": {
        TITLE: "Stake SRKb and Earn SRKb",
        TOKEN1: 'SRKb',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-BCP-V1": {
        TITLE: "Stake SRKb and Earn BCP",
        TOKEN1: 'SRKb',
        TOKEN2: 'BCP',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "BCP",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "BCP-SFUEL-V1": {
        TITLE: "Stake BCP and Earn SFUEL",
        TOKEN1: 'BCP',
        TOKEN2: 'SFUEL',
        STAKE_TYPE: "BCP",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-AIRx-V1": {
        TITLE: "Stake SRKb and Earn AIRx",
        TOKEN1: 'SRKb',
        TOKEN2: 'AIRx',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "AIRx",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-FiG-V1": {
        TITLE: "Stake SRKb and Earn FiG",
        TOKEN1: 'SRKb',
        TOKEN2: 'FiG',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "FiG",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "KGO-SRKb-V1": {
        TITLE: "Stake KGO and Earn SRKb",
        TOKEN1: 'KGO',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "KGO",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-KGO-V1": {
        TITLE: "Stake SRKb and Earn KGO",
        TOKEN1: 'SRKb',
        TOKEN2: 'KGO',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "KGO",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-OWN-V1": {
        TITLE: "Stake SRKb and Earn OWN",
        TOKEN1: 'SRKb',
        TOKEN2: 'OWN',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "OWN",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "OWN-OWN-V1": {
        TITLE: "Stake OWN and Earn OWN",
        TOKEN1: 'OWN',
        TOKEN2: 'OWN',
        STAKE_TYPE: "OWN",
        REWARD_TYPE: "OWN",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-KCLP-V1": {
        TITLE: "Stake SRKb and Earn KCLP",
        TOKEN1: 'SRKb',
        TOKEN2: 'KCLP',
        STAKE_TYPE: "SRKb",
        REWARD_TYPE: "KCLP",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "KCLP-SRKb-V1": {
        TITLE: "Stake KCLP and Earn SRKb",
        TOKEN1: 'KCLP',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "KCLP",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
};
