import Web3Instance from "../../Web3Instance";
import {BigNumber} from 'ethers';
import {Token as token, TokenAmount} from "@uniswap/sdk";
import {buildTxUrl, expandValue} from "../../helpers";

class Token {
    initialized = false;

    constructor(contractData) {
        if (this.initialized) {
            return;
        }
        this.contractData = contractData;
    }

    async init() {
        if (this.initialized) {
            return;
        }
        await Web3Instance.init();

        this.web3 = await Web3Instance.getRawWeb3();
        this.contract = await Web3Instance.initContract(this.contractData);
        this.raw = this.contract;
        this.token = new token(
            Web3Instance.chainId, this.getAddress(), this.getDecimals(), this.getSymbol(), this.getName()
        );
        this.initialized = true;
    }

    getName() {
        return this.contractData.contractName;
    }

    getAddress() {
        return this.contractData.chain[Web3Instance.chainId].address;
    }

    getDecimals() {
        return this.contractData.decimals;
    }

    getSymbol() {
        return this.contractData.symbol;
    }

    async balanceOf(address, toTokenAmount = false) {
        await this.init();

        let bal = await this.contract.methods.balanceOf(address).call();
        if (toTokenAmount) {
            return new TokenAmount(this.token, bal)
        }

        return bal;
    }

    async getNonces(address) {
        await this.init();

        return await this.contract.methods.nonces(address).call()
    }

    async allowance(owner, spender, toTokenAmount = false) {
        await this.init();

        let bal = await this.contract.methods.allowance(owner, spender).call();

        if (toTokenAmount) {
            return new TokenAmount(this.token, bal);
        }

        return bal;
    }

    async approve(owner, spender, value) {
        await this.init();

        if (this.getDecimals() < 18) {
            value = expandValue(this.web3, value, this.getDecimals())
        } else {
            value = this.web3.utils.toWei(value.toString());
        }

        let txData = await this.contract.methods.approve(
            spender,
            value.toString()
        ).send({from: owner});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async totalSupply() {
        await this.init();

        let bal = await this.contract.methods.totalSupply().call();

        return bal;
    }
}

export default Token;
