import SfuelBnbStakingRewardsABI from '../abis/SfuelBnbStakingRewards.json'
import {REACT_APP_PANCAKEAPP_URL, SFUEL, SFUELBNB_CAKELP} from '../../../../constants';
import StakingRewards from "../../../../eth/staking/liquidity/StakingRewards";

class SfuelBnbStakingRewards extends StakingRewards {
    initialized = false;

    constructor() {
        super(SfuelBnbStakingRewardsABI, {
            rewardsToken: SFUEL,
            pairToken: 'ETH',
            stakingToken: SFUELBNB_CAKELP,
            dexUrl: REACT_APP_PANCAKEAPP_URL
        });
    }
};

export default SfuelBnbStakingRewards;
