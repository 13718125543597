import {ETHEREUM_CHAIN} from "../../tokens/constants";
import {setCardDetails} from "../../../helpers";

export const getEthLPStakingCardDetails = (blockchain, staking) => {
    let stakingRewardsSRK = setCardDetails();
    let stakingRewardsSrkUsdt = setCardDetails();

    if (blockchain.toLowerCase() === ETHEREUM_CHAIN.toLowerCase()) {
        stakingRewardsSRK = setCardDetails(
            staking.srkEthPoolV3,
            true,
            'SRK',
            'UNI-V2',
            'SRK-ETH',
            ['V1', 'V2', 'V3']
        );

        stakingRewardsSrkUsdt = setCardDetails(
            staking.srkUsdtPool,
            true,
            'SRK',
            'UNI-V2',
            'SRK-USDT',
            ['V1']
        );
    }

    return {
        stakingRewardsSRK: stakingRewardsSRK,
        stakingRewardsSrkUsdt: stakingRewardsSrkUsdt,
    };
}
