import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class KclpBusdStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.kclp;
        this.lpTokenBal = props.wallet.balance.kclpBusdCakeLP;

        this.lpTokenContract = props.contracts.kclpBusdCakeLPToken;
        this.stakingRewardsContract = props.contracts['kclpBusdStakingRewards' + props.match.params.version];

        this.poolData = props.staking['kclpBusdPool' + props.match.params.version];
    }
}

export default withRouter(KclpBusdStakeActionsPage);
