import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {withLayout} from './WrappedPages';
import LiquidityStakingPage from '../../pages/liquidity-staking/LiquidityStakingPage';
import PoolBasedStakingPage from '../../pages/pool-based-staking/PoolBasedStakingPage';
import P2PLendingPage from '../../pages/p2p-lending/P2PLendingPage';
import AssetsManagementPage from '../../pages/assets-management/AssetsManagementPage';
import RoadmapPage from '../../pages/roadmap/RoadmapPage';
import SFuelTokenPage from '../../pages/sfuel-token/SFuelTokenPage';
import NotFoundPage from '../../pages/not-found/NotFoundPage';

import {SrkEthStakeActionsPage, SrkUsdtActionsPage} from '../../pages/liquidity-staking/eth'

import {
    AirxBnbStakeActionsPage,
    BcpBnbStakeActionsPage,
    BsrkBnbStakeActionsPage,
    ChimomBnbStakeActionsPage,
    FigBnbStakeActionsPage,
    KclpBusdSfuelStakeActionsPage,
    KclpBusdStakeActionsPage,
    KgoBnbStakeActionsPage,
    OwnBnbStakeActionsPage,
    SfuelBnbStakeActionsPage,
    SrkbBnbStakeActionsPage,
    SrkbBusdStakeActionsPage
} from '../../pages/liquidity-staking/bsc'

import {
    BcpSfuelPool,
    KclpSrkbPool,
    KgoSrkbPool,
    OwnOwnPool,
    SfuelSfuelPool,
    SrkbAirxPool,
    SrkbBcpPool,
    SrkbFigPool,
    SrkbKclpPool,
    SrkbKgoPool,
    SrkbOwnPool,
    SrkbSrkbPool,
    SrkSfuelPool
} from "../../pages/pool-based-staking/pools";

const redirectTo404 = () => (
    <Redirect to="/404"/>
);


const Router = (props) => {
    let wLayout = withLayout(props);
    // console.log(props);
    const Stake = wLayout(LiquidityStakingPage);
    const P2PLending = wLayout(P2PLendingPage);
    const AssetsManagement = wLayout(AssetsManagementPage);
    const Roadmap = wLayout(RoadmapPage);
    const SFuelToken = wLayout(SFuelTokenPage);
    const NotFound = wLayout(NotFoundPage);
    const SrkEthStakeActions = wLayout(SrkEthStakeActionsPage);
    const SfuelBnbStakeActions = wLayout(SfuelBnbStakeActionsPage);
    const SrkUsdtActions = wLayout(SrkUsdtActionsPage);
    const BsrkBnbStakeActions = wLayout(BsrkBnbStakeActionsPage)
    const SrkbBnbStakeActions = wLayout(SrkbBnbStakeActionsPage)
    const SrkbBusdStakeActions = wLayout(SrkbBusdStakeActionsPage)
    const ChimomBnbStakeActions = wLayout(ChimomBnbStakeActionsPage)
    const BcpBnbStakeActions = wLayout(BcpBnbStakeActionsPage)
    const AirxBnbStakeActions = wLayout(AirxBnbStakeActionsPage)
    const FigBnbStakeActions = wLayout(FigBnbStakeActionsPage)
    const KgoBnbStakeActions = wLayout(KgoBnbStakeActionsPage)
    const OwnBnbStakeActions = wLayout(OwnBnbStakeActionsPage)
    const KclpBusdStakeActions = wLayout(KclpBusdStakeActionsPage)
    const KclpBusdSfuelStakeActions = wLayout(KclpBusdSfuelStakeActionsPage)

    const Pool = wLayout(PoolBasedStakingPage);
    const SrkSfuelP = wLayout(SrkSfuelPool);
    const SfuelSfuelP = wLayout(SfuelSfuelPool);
    const SrkbSrkbP = wLayout(SrkbSrkbPool);
    const SrkbBcpP = wLayout(SrkbBcpPool);
    const BcpSfuelP = wLayout(BcpSfuelPool);
    const SrkbAirxP = wLayout(SrkbAirxPool);
    const SrkbFigP = wLayout(SrkbFigPool);
    const KgoSrkbP = wLayout(KgoSrkbPool);
    const SrkbKgoP = wLayout(SrkbKgoPool);
    const SrkbOwnP = wLayout(SrkbOwnPool);
    const OwnOwnP = wLayout(OwnOwnPool);
    const SrkbKclpP = wLayout(SrkbKclpPool);
    const KclpSrkbP = wLayout(KclpSrkbPool);
    // const Vote = wLayout(_Vote);
    // const Bridge = wLayout(_Bridge);
    const DefaultApp = Stake;


    const lpPages = {
        'SRK-ETH': {
            'V1': <SrkEthStakeActions/>,
            'V2': <SrkEthStakeActions/>,
            'V3': <SrkEthStakeActions/>,
        },
        'SRK-USDT': {
            'V1': <SrkUsdtActions/>,
        },
        'SFUEL-BNB': {
            'V1': <SfuelBnbStakeActions/>,
        },
        'BSRK-BNB': {
            'V1': <BsrkBnbStakeActions/>,
        },
        'SRKb-BNB': {
            'V1': <SrkbBnbStakeActions/>,
            'V2': <SrkbBnbStakeActions/>,
        },
        'SRKb-BUSD': {
            'V1': <SrkbBusdStakeActions/>,
        },
        'CHIMOM-BNB': {
            'V1': <ChimomBnbStakeActions/>,
        },
        'BCP-BNB': {
            'V1': <BcpBnbStakeActions/>,
        },
        'AIRx-BNB': {
            'V1': <AirxBnbStakeActions/>,
        },
        'FIG-BNB': {
            'V1': <FigBnbStakeActions/>,
        },
        'KGO-BNB': {
            'V1': <KgoBnbStakeActions/>,
        },
        'OWN-BNB': {
            'V1': <OwnBnbStakeActions/>,
        },
        'KCLP-BUSD': {
            'V1': <KclpBusdStakeActions/>,
        },
        'KCLP-BUSD-SFUEL': {
            'V1': <KclpBusdSfuelStakeActions/>,
        },
    }
    const poolPages = {
        'SRKb-SFUEL': {
            'V1': <SrkSfuelP/>,
        },
        'SFUEL-SFUEL': {
            'V1': <SfuelSfuelP/>,
        },
        'SRKb-SRKb': {
            'V1': <SrkbSrkbP/>,
        },
        'SRKb-BCP': {
            'V1': <SrkbBcpP/>,
        },
        'BCP-SFUEL': {
            'V1': <BcpSfuelP/>,
        },
        'SRKb-AIRx': {
            'V1': <SrkbAirxP/>,
        },
        'SRKb-FiG': {
            'V1': <SrkbFigP/>,
        },
        'KGO-SRKb': {
            'V1': <KgoSrkbP/>,
        },
        'SRKb-KGO': {
            'V1': <SrkbKgoP/>,
        },
        'SRKb-OWN': {
            'V1': <SrkbOwnP/>,
        },
        'OWN-OWN': {
            'V1': <OwnOwnP/>,
        },
        'SRKb-KCLP': {
            'V1': <SrkbKclpP/>,
        },
        'KCLP-SRKb': {
            'V1': <KclpSrkbP/>,
        },
    }

    return (
        <Switch>
            <Route
                exact
                path="/"
                component={DefaultApp}
            />
            {/* <Route
                exact
                path="/sparkdefi-web-app"
                component={DefaultApp}
            />
            <Route
                exact
                path="/lend"
                component={P2PLending}
            />
            <Route
                exact
                path="/manage"
                component={AssetsManagement}
            />
            <Route
                exact
                path="/roadmap"
                component={Roadmap}
            />
            <Route
                exact
                path="/sfuel-token"
                component={SFuelToken}
            />
            <Route
                exact
                path="/bridge"
                component={() => window.location.replace('https://app.sparkswap.finance/#/bridge')}
            />
            <Route
                exact
                path="/vote"
                component={Vote}
            />
            <Route
                path="/404"
                render={NotFound}/>
            <Route
                path="*"
                render={redirectTo404}/> */}
            <Route
                exact
                path="/bridge"
                component={() => window.location.replace('https://app.sparkswap.finance/#/bridge')}
            />
            <Route
                exact
                path="/stake"
                component={Stake}
            />
            <Route
                exact
                path="/stake/liquidity/:category/:version"
                render={({match}) => (<>{lpPages[match.params.category][match.params.version]}</>)}
            />
            <Route
                exact
                path="/stake/pool/:category/:version"
                render={({match}) => (<>{poolPages[match.params.category][match.params.version]}</>)}
            />
            <Route
                exact
                path="/pool"
                component={Pool}
            />
            {/*<Route
                exact
                path="/stake"
                // component={Stake}
                component={() => {
                    window.location.replace('https://app.sparkswap.finance/#/farms');
                    return null;
                }}
            />
            <Route
                exact
                path="/pool"
                component={() => {
                    window.location.replace('https://app.sparkswap.finance/#/pools');
                    return null;
                }}
            />*/}
            {/*<Route*/}
            {/*    // exact*/}
            {/*    component={() => {*/}
            {/*        window.location.replace('https://app.sparkswap.finance');*/}
            {/*        return null;*/}
            {/*    }}*/}
            {/*/>*/}
        </Switch>
    );
};

export default withRouter(Router);
