import {BINANCE_CHAIN_ID} from "../../constants";

export const BSC_LP_PAIR_INFO = {
    "SFUEL-BNB-V1": {
        TITLE: "Stake SFUEL-BNB Cake-LP and Earn SFUEL",
        TOKEN1: 'BNB',
        TOKEN2: 'SFUEL',
        STAKE_TYPE: "SFUEL-BNB Cake-LP",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "BSRK-BNB-V1": {
        TITLE: "Stake bSRK-BNB Cake-LP and Earn bSRK",
        TOKEN1: 'BNB',
        TOKEN2: 'bSRK',
        STAKE_TYPE: "bSRK-BNB Cake-LP",
        REWARD_TYPE: "bSRK",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-BNB-V1": {
        TITLE: "Stake SRKb-BNB Spark-LP and Earn SRKb",
        TOKEN1: 'BNB',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "SRKb-BNB Spark-LP",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-BNB-V2": {
        TITLE: "Stake SRKb-BNB Spark-LP and Earn SRKb",
        TOKEN1: 'BNB',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "SRKb-BNB Spark-LP",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "SRKb-BUSD-V1": {
        TITLE: "Stake SRKb-BUSD Spark-LP and Earn SRKb",
        TOKEN1: 'BUSD',
        TOKEN2: 'SRKb',
        STAKE_TYPE: "SRKb-BUSD Spark-LP",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "CHIMOM-BNB-V1": {
        TITLE: "Stake CHIMOM-BNB Spark-LP and Earn CHIMOM",
        TOKEN1: 'BNB',
        TOKEN2: 'CHIMOM',
        STAKE_TYPE: "CHIMOM-BNB Spark-LP",
        REWARD_TYPE: "CHIMOM",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "BCP-BNB-V1": {
        TITLE: "Stake BCP-BNB Spark-LP and Earn BCP",
        TOKEN1: 'BNB',
        TOKEN2: 'BCP',
        STAKE_TYPE: "BCP-BNB Spark-LP",
        REWARD_TYPE: "BCP",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "AIRx-BNB-V1": {
        TITLE: "Stake AIRx-BNB Spark-LP and Earn SFUEL",
        TOKEN1: 'BNB',
        TOKEN2: 'AIRx',
        STAKE_TYPE: "AIRx-BNB Spark-LP",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "FIG-BNB-V1": {
        TITLE: "Stake FiG-BNB Spark-LP and Earn SFUEL",
        TOKEN1: 'BNB',
        TOKEN2: 'FiG',
        STAKE_TYPE: "FiG-BNB Spark-LP",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "KGO-BNB-V1": {
        TITLE: "Stake KGO-BNB Spark-LP and Earn SRKb",
        TOKEN1: 'BNB',
        TOKEN2: 'KGO',
        STAKE_TYPE: "KGO-BNB Spark-LP",
        REWARD_TYPE: "SRKb",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "OWN-BNB-V1": {
        TITLE: "Stake OWN-BNB Spark-LP and Earn OWN",
        TOKEN1: 'BNB',
        TOKEN2: 'OWN',
        STAKE_TYPE: "OWN-BNB Spark-LP",
        REWARD_TYPE: "OWN",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "KCLP-BUSD-V1": {
        TITLE: "Stake KCLP-BUSD Cake-LP and Earn KCLP",
        TOKEN1: 'BUSD',
        TOKEN2: 'KCLP',
        STAKE_TYPE: "KCLP-BUSD Cake-LP",
        REWARD_TYPE: "KCLP",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
    "KCLP-BUSD-SFUEL-V1": {
        TITLE: "Stake KCLP-BUSD Spark-LP and Earn SFUEL",
        TOKEN1: 'BUSD',
        TOKEN2: 'KCLP',
        STAKE_TYPE: "KCLP-BUSD Spark-LP",
        REWARD_TYPE: "SFUEL",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Binance Smart Chain',
        SUPPORTED_NETWORK_ID: BINANCE_CHAIN_ID,
        EXPLORER: 'BscScan',
    },
};
