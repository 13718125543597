import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {HttpLink} from 'apollo-link-http'

export const srkbsrkbpool = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/aldrickb/srkbsrkbpoollite'
    }),
    cache: new InMemoryCache(),
    fetchPolicy: "no-cache",
    shouldBatch: true
})

export const sfuelsfuelpool = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/aldrickb/sfuelsfuelpoollite'
    }),
    cache: new InMemoryCache(),
    fetchPolicy: "no-cache",
    shouldBatch: true
})

export const srkbsfuelpool = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/aldrickb/srkbsfuelpoolite'
    }),
    cache: new InMemoryCache(),
    fetchPolicy: "no-cache",
    shouldBatch: true
})
// export const srkbsrkbpool = false
//
// export const sfuelsfuelpool = false
//
// export const srkbsfuelpool = false
