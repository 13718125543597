import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class BcpBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.bcp;
        this.lpTokenBal = props.wallet.balance.bcpBnbSparkLP;

        this.lpTokenContract = props.contracts.bcpBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['bcpBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['bcpBnbPool' + props.match.params.version];
    }
}

export default withRouter(BcpBnbStakeActionsPage);
