import {buildTxUrl, onWrongNetwork} from "../../../helpers";
import Web3Instance from "../../../Web3Instance";
import {TokenAmount} from "@uniswap/sdk";
import { totalDeposit } from "../../../apollo/queries"

class PoolBasedStakingV1 {
    constructor(contractData, options) {
        this.init(contractData, options).then(r => {
        });
    }

    /**
     * Initializes the contract then returns the raw web3 contract
     * @returns {Promise<*>}
     * @param options
     */
    async init(contractData, options) {
        if (this.initialized) {
            return this.contract;
        }
        try {
            this.web3Instance = await Web3Instance.init();
            this.initialized = true;
            this.web3 = await Web3Instance.getRawWeb3();
            this.contract = await this.initContract(contractData);
            this.chainId = await this.web3Instance.getChainID();
            this.rewardToken = options.rewardsToken[this.chainId];
            this.stakingToken = options.stakingToken[this.chainId];
            this.stakeTokenInstance = options.stakeTokenInstance;
            this.dexUrl = options.dexUrl;
            this.rewardTokenDexUrl = options.rewardTokenDexUrl;
            this.pairToken = options.pairToken;
            this.remainingRewardBlocks = await this.getRemainingRewardBlocks();
            this.graphQL = options.graphQL;
        } catch (e) {
            console.log(e);
            onWrongNetwork();
        }

        return this.contract;
    }

    async initContract(contractData) {
        return new this.web3.eth.Contract(contractData.abi, contractData.address);
    }

    async getSwapUrl() {
        return this.dexUrl
            + '/#/swap?inputCurrency='
            + this.pairToken
            + '&outputCurrency='
            + this.stakingToken.address
    }

    async getRewardUrl() {
        return this.rewardTokenDexUrl;
    }

    async getAddress() {
        return await this.contract.options.address;
    }

    async getStakedTokens(address, toTokenAmount = false) {
        let userInfo = await this.contract.methods.userInfo(address).call();
        let tokenAmount = new TokenAmount(this.stakingToken, userInfo.amount);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getEarnedTokens(address, toTokenAmount = false) {
        let pendingReward = await this.contract.methods.pendingReward(address).call();
        let tokenAmount = new TokenAmount(this.rewardToken, pendingReward);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getRewardPerBlock(toTokenAmount = false) {
        let rewardPerBlock = await this.contract.methods.rewardPerBlock().call();
        let tokenAmount = new TokenAmount(this.rewardToken, rewardPerBlock);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getRemainingRewardBlocks() {
        const endBlock = await this.contract.methods.bonusEndBlock().call();
        const currentBlock = await this.web3.eth.getBlockNumber()

        return currentBlock >= endBlock ? 0 : endBlock - currentBlock;
    }

    async getTotalDeposits(toDecimal = false, toTokenAmount = false) {
        try{
            const res = await this.graphQL.query({
                query: totalDeposit
            })

            let balance = res.data.sparkPoolData.totalStaked;
            if (toDecimal) {
                balance = this.web3.utils.fromWei(balance);
            }
            let tokenAmount = new TokenAmount(this.stakingToken, balance);

            if (toTokenAmount) {
                return tokenAmount;
            }

            return tokenAmount.toSignificant(15);
        }
        catch(e){
           console.log(e);
           return 0;
        }
    }

    async getRewardRate() {
        let rewardPerBlock = await this.contract.methods.rewardPerBlock().call();

        let tokenAmount = new TokenAmount(this.stakingToken, rewardPerBlock);

        return tokenAmount.toSignificant(15);
    }

    async getStartingBlock() {
        return await this.contract.methods.startBlock().call();
    }

    async getEndingBlock() {
        let block = await this.contract.methods.bonusEndBlock().call();
        return block;
    }

    async dynamicApySparkSwap(){
        try {
            let assets = await fetch("https://api.sparkswap.info/api/assets");
            assets = await assets.json();

            let stakingPrice = assets[this.web3.utils.toChecksumAddress(this.stakingToken.address)]["last_price"];
            let rewardPrice = assets[this.web3.utils.toChecksumAddress(this.rewardToken.address)]["last_price"];
            let bnbPrice = assets["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"]["last_price"];

            return await this.apy(stakingPrice/bnbPrice , rewardPrice/bnbPrice);
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    async apy(stakingTokenPriceinBNB = 1, rewardsTokenPriceinBNB = 1) {
        let BLOCKS_PER_YEAR = 10513333; // Blocks per year in BSC

        let rewardPerBlock = await this.contract.methods.rewardPerBlock().call();
        rewardPerBlock = new TokenAmount(this.rewardToken, rewardPerBlock).toSignificant(15);
        let totalStaked = await this.getTotalDeposits();

        let totalRewardPricePerYear = rewardsTokenPriceinBNB * rewardPerBlock * BLOCKS_PER_YEAR;
        let totalStakingTokensInPool = stakingTokenPriceinBNB * totalStaked;

        return (totalRewardPricePerYear / totalStakingTokensInPool) * 100;
    }

    async getMaxStake() {
        return this.web3.utils.fromWei(await this.contract.methods.maxStaking().call());
    }

    async stake(address, amount) {
        amount = this.web3.utils.toWei(amount.toString());

        let txData = await this.contract.methods.enterStaking(amount).send({from: address});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async withdraw(address, amount) {
        amount = this.web3.utils.toWei(amount.toString());

        let txData = await this.contract.methods.leaveStaking(amount).send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async claim(address, amount) {
        amount = this.web3.utils.toWei(amount.toString());
        let txData = await this.contract.methods.enterStaking(amount).send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    hasEnded() {
        return this.remainingRewardBlocks <= 0;
    }

    async getRemainingDays() {
        let remainingRewardBlocks = await this.getRemainingRewardBlocks();
        let diff = remainingRewardBlocks * 3 * 1000;
        let remDays = parseInt(diff / (1000*60*60*24) + '');

        if (remDays === 0) {
            return `${diff / (1000 * 60)} Minutes`;
        }
        return `${remDays} ${(remDays > 1 ? 'Days' : 'Day' )}`;
    }
}

export default PoolBasedStakingV1;
