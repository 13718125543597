import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class KclpBusdSfuelStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.kclp;
        this.lpTokenBal = props.wallet.balance.kclpBusdSparkLP;

        this.lpTokenContract = props.contracts.kclpBusdSparkLPToken;
        this.stakingRewardsContract = props.contracts['kclpBusdSfuelStakingRewards' + props.match.params.version];

        this.poolData = props.staking['kclpBusdSfuelPool' + props.match.params.version];
    }
}

export default withRouter(KclpBusdSfuelStakeActionsPage);
