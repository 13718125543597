import {BINANCE_SMART_CHAIN} from "../../constants";
import {setCardDetails} from "../../../helpers";

export const getBscLPStakingCardDetails = (blockchain, staking) => {
    let stakingRewardsSFUEL = setCardDetails({}, false, 'SFUEL', 'CAKE-LP');
    let stakingRewardsBSrkBnb = setCardDetails({}, false, 'bSRK', 'CAKE-LP');
    let stakingRewardsSrkbBnb = setCardDetails({}, false, 'SRKb', 'Spark-LP');
    let stakingRewardsChimomBnb = setCardDetails({}, false, 'CHIMOM', 'Spark-LP');
    let stakingRewardsSrkbBusd = setCardDetails({}, false, 'SRKb', 'Spark-LP');
    let stakingRewardsBcpBnb = setCardDetails({}, false, 'BCP', 'Spark-LP');
    let stakingRewardsAirxBnb = setCardDetails({}, false, 'SFUEL', 'Spark-LP');
    let stakingRewardsFigBnb = setCardDetails({}, false, 'SFUEL', 'Spark-LP');
    let stakingRewardsKgoBnb = setCardDetails({}, false, 'SRKb', 'Spark-LP');
    let stakingRewardsOwnBnb = setCardDetails({}, false, 'OWN', 'Spark-LP');
    let stakingRewardsKclpBusd = setCardDetails({}, false, 'KCLP', 'Cake-LP');
    let stakingRewardsKclpBusdSfuel = setCardDetails({}, false, 'SFUEL', 'Spark-LP');

    if (blockchain === BINANCE_SMART_CHAIN) {
        stakingRewardsSFUEL = setCardDetails(
            staking.sfuelBnbPool,
            true,
            'SFUEL',
            'CAKE-LP',
            'SFUEL-BNB',
            ['V1']
        );
        stakingRewardsBSrkBnb = setCardDetails(
            staking.bsrkBnbPool,
            true,
            'bSRK',
            'CAKE-LP',
            'BSRK-BNB',
            ['V1']
        );
        stakingRewardsSrkbBnb = setCardDetails(
            staking.srkbBnbPoolV2,
            true,
            'SRKb',
            'Spark-LP',
            'SRKb-BNB',
            ['V1', 'V2']
        );
        stakingRewardsSrkbBusd = setCardDetails(
            staking.srkbBusdPool,
            true,
            'SRKb',
            'Spark-LP',
            'SRKb-BUSD',
            ['V1']
        );
        stakingRewardsChimomBnb = setCardDetails(
            staking.chimomBnbPoolV1,
            true,
            'CHIMOM',
            'Spark-LP',
            'CHIMOM-BNB',
            ['V1']
        );
        stakingRewardsBcpBnb = setCardDetails(
            staking.bcpBnbPoolV1,
            true,
            'BCP',
            'Spark-LP',
            'BCP-BNB',
            ['V1']
        );
        stakingRewardsAirxBnb = setCardDetails(
            staking.airxBnbPoolV1,
            true,
            'SFUEL',
            'Spark-LP',
            'AIRx-BNB',
            ['V1']
        );
        stakingRewardsFigBnb = setCardDetails(
            staking.figBnbPoolV1,
            true,
            'SFUEL',
            'Spark-LP',
            'FIG-BNB',
            ['V1']
        );
        stakingRewardsKgoBnb = setCardDetails(
            staking.kgoBnbPoolV1,
            true,
            'SRKb',
            'Spark-LP',
            'KGO-BNB',
            ['V1']
        );
        stakingRewardsOwnBnb = setCardDetails(
            staking.ownBnbPoolV1,
            true,
            'OWN',
            'Spark-LP',
            'OWN-BNB',
            ['V1']
        );
        stakingRewardsKclpBusd = setCardDetails(
            staking.kclpBusdPoolV1,
            true,
            'KCLP',
            'Cake-LP',
            'KCLP-BUSD',
            ['V1']
        );
        stakingRewardsKclpBusdSfuel = setCardDetails(
            staking.kclpBusdSfuelPoolV1,
            true,
            'SFUEL',
            'Spark-LP',
            'KCLP-BUSD-SFUEL',
            ['V1']
        );
    }

    return {
        stakingRewardsSFUEL: stakingRewardsSFUEL,
        stakingRewardsBSrkBnb: stakingRewardsBSrkBnb,
        stakingRewardsSrkbBnb: stakingRewardsSrkbBnb,
        stakingRewardsChimomBnb: stakingRewardsChimomBnb,
        stakingRewardsSrkbBusd: stakingRewardsSrkbBusd,
        stakingRewardsBcpBnb: stakingRewardsBcpBnb,
        stakingRewardsAirxBnb: stakingRewardsAirxBnb,
        stakingRewardsFigBnb: stakingRewardsFigBnb,
        stakingRewardsKgoBnb: stakingRewardsKgoBnb,
        stakingRewardsOwnBnb: stakingRewardsOwnBnb,
        stakingRewardsKclpBusd: stakingRewardsKclpBusd,
        stakingRewardsKclpBusdSfuel: stakingRewardsKclpBusdSfuel,
    };
}
