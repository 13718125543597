import {setPoolStakingData} from "../../../helpers";

export const BSC_POOL_STAKING = {
    list: {
        srkbSfuelLPoolV1: {},
        sfuelSfuelLPoolV1: {},
        srkbSrkbLPoolV1: {},
        srkbBcpLPoolV1: {},
        bcpSfuelLPoolV1: {},
        srkbAirxLPoolV1: {},
        srkbFigLPoolV1: {},
        kgoSrkbLPoolV1: {},
        srkbKgoLPoolV1: {},
        srkbOwnLPoolV1: {},
        ownOwnLPoolV1: {},
        srkbKclpLPoolV1: {},
        kclpSrkbLPoolV1: {},
    },
    contracts: {
        // SRKb-SFUEL LAUNCHPOOL
        srkbSfuelLaunchPoolV1: null,
        // SFUEL-SFUEL LAUNCHPOOL
        sfuelSfuelLaunchPoolV1: null,
        // SRKb-SRKb LAUNCHPOOL
        srkbSrkbLaunchPoolV1: null,
        // SRKb-BCP LAUNCHPOOL
        srkbBcpLaunchPoolV1: null,
        // BCP-SFUEL LAUNCHPOOL
        bcpSfuelLaunchPoolV1: null,
        // SRKb-AIRx LAUNCHPOOL
        srkbAirxLaunchPoolV1: null,
        // SRKb-FIG LAUNCHPOOL
        srkbFigLaunchPoolV1: null,
        // KGO-SRKb LAUNCHPOOL
        kgoSrkbLaunchPoolV1: null,
        // SRKb-KGO LAUNCHPOOL
        srkbKgoLaunchPoolV1: null,
        srkbOwnLaunchPoolV1: null,
        ownOwnLaunchPoolV1: null,
        srkbKclpLaunchPoolV1: null,
        kclpSrkbLaunchPoolV1: null,
    }
}

export const updateLaunchPoolData = async (selectedWalletAddress, contracts) => {
    let stakingDetails = {};
    stakingDetails.srkbSfuelLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbSfuelLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.sfuelSfuelLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.sfuelSfuelLaunchPoolV1,
        contracts.sfuelToken
    )
    stakingDetails.srkbSrkbLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbSrkbLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.srkbBcpLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbBcpLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.bcpSfuelLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.bcpSfuelLaunchPoolV1,
        contracts.bcpToken
    )
    stakingDetails.srkbAirxLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbAirxLaunchPoolV1,
        contracts.airxToken
    )
    stakingDetails.srkbFigLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbFigLaunchPoolV1,
        contracts.figToken
    )
    stakingDetails.kgoSrkbLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.kgoSrkbLaunchPoolV1,
        contracts.kgoToken
    )
    stakingDetails.srkbKgoLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbKgoLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.srkbOwnLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbOwnLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.ownOwnLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.ownOwnLaunchPoolV1,
        contracts.ownToken
    )
    stakingDetails.srkbKclpLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.srkbKclpLaunchPoolV1,
        contracts.srkbToken
    )
    stakingDetails.kclpSrkbLPoolV1 = await setPoolStakingData(
        selectedWalletAddress,
        contracts.kclpSrkbLaunchPoolV1,
        contracts.kclpToken
    )

    return stakingDetails;
};
