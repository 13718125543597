import {withRouter} from 'react-router-dom';
import PoolActionsPage from "../PoolActionsPage";

class OwnOwnPool extends PoolActionsPage {
    constructor(props) {
        super(props);
        this.poolData = props.staking['ownOwnLPool' + props.match.params.version];
        this.stakeTokenBal = props.wallet.balance.own;
        this.rewardTokenBal = props.wallet.balance.own;
        this.stakeTokenContract = props.contracts.ownToken;
        this.stakingRewardsContract = props.contracts.ownOwnLaunchPoolV1;
    }
}

export default withRouter(OwnOwnPool);
