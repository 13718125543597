import {bscLiquidityStaking} from "./liquidityStakingList";
import {LiquidityStakingRewards as BscLPStakingRewards} from "./LiquidityStakingRewards";
import {
    AIRx,
    AIRxBNB_SPARKLP,
    BCP,
    BCPBNB_SPARKLP, BUSD,
    CHIMOM,
    CHIMOMBNB_SPARKLP,
    FIG,
    FIGBNB_SPARKLP,
    KGO,
    KGOBNB_SPARKLP, REACT_APP_PANCAKEAPP_URL,
    REACT_APP_SPARKSWAP_URL,
    SFUEL,
    SRKb,
    SRKBBNB_SPARKLP
} from "../../../constants";
import {BsrkBnbStakingRewards, SfuelBnbStakingRewards, SrkbBusdStakingRewards} from "./pairs";
import {KCLP, KCLPBUSD_CAKELP, KCLPBUSD_SPARKLP, OWN, OWNBNB_SPARKLP} from "../../tokens/constants";

let lpStakingContracts = {};

export const initBscLiquidityStakingContracts = async (tokens) => {
    bscLiquidityStaking.list['CHIMOM-BNB'].map((address, index) => {
        lpStakingContracts['chimomBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: CHIMOM,
                rewardsToken: CHIMOM,
                pairToken: 'ETH',
                stakingToken: CHIMOMBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.chimomBnbSparkLPToken
            }
        );
    })


    bscLiquidityStaking.list['BCP-BNB'].map((address, index) => {
        lpStakingContracts['bcpBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: BCP,
                rewardsToken: BCP,
                pairToken: 'ETH',
                stakingToken: BCPBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.bcpBnbSparkLPToken
            }
        );
    })

    bscLiquidityStaking.list['AIRx-BNB'].map((address, index) => {
        lpStakingContracts['airxBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: AIRx,
                rewardsToken: SFUEL,
                pairToken: 'ETH',
                stakingToken: AIRxBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.airxBnbSparkLPToken
            }
        );
    })

    bscLiquidityStaking.list['FIG-BNB'].map((address, index) => {
        lpStakingContracts['figBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: FIG,
                rewardsToken: SFUEL,
                pairToken: 'ETH',
                stakingToken: FIGBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.figBnbSparkLPToken
            }
        );
    })

    bscLiquidityStaking.list['KGO-BNB'].map((address, index) => {
        lpStakingContracts['kgoBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: KGO,
                rewardsToken: SRKb,
                pairToken: 'ETH',
                stakingToken: KGOBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.kgoBnbSparkLPToken,
            }
        );
    })

    bscLiquidityStaking.list['OWN-BNB'].map((address, index) => {
        lpStakingContracts['ownBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: OWN,
                rewardsToken: OWN,
                pairToken: 'ETH',
                stakingToken: OWNBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.ownBnbSparkLPToken,
            }
        );
    })

    bscLiquidityStaking.list['SRKb-BNB'].map((address, index) => {
        lpStakingContracts['srkbBnbStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: SRKb,
                rewardsToken: SRKb,
                pairToken: 'ETH',
                stakingToken: SRKBBNB_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.srkbBnbSparkLPToken,
            }
        );
    })

    bscLiquidityStaking.list['KCLP-BUSD'].map((address, index) => {
        lpStakingContracts['kclpBusdStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: KCLP,
                rewardsToken: KCLP,
                pairToken: BUSD,
                stakingToken: KCLPBUSD_CAKELP,
                dexUrl: REACT_APP_PANCAKEAPP_URL,
                lpTokenInstance: tokens.kclpBusdCakeLPToken,
            }
        );
    })
    bscLiquidityStaking.list['KCLP-BUSD-SFUEL'].map((address, index) => {
        lpStakingContracts['kclpBusdSfuelStakingRewardsV' + (index + 1)] = new BscLPStakingRewards(
            {
                address: address,
                abi: bscLiquidityStaking.abi
            },
            {
                mainToken: KCLP,
                rewardsToken: SFUEL,
                pairToken: BUSD,
                stakingToken: KCLPBUSD_SPARKLP,
                dexUrl: REACT_APP_SPARKSWAP_URL,
                lpTokenInstance: tokens.kclpBusdSparkLPToken,
            }
        );
    })
    lpStakingContracts.sfuelBnbStakingRewards = new SfuelBnbStakingRewards();
    lpStakingContracts.bsrkBnbStakingRewards = new BsrkBnbStakingRewards();
    lpStakingContracts.srkbBusdStakingRewards = new SrkbBusdStakingRewards(tokens.srkbBusdSparkLPToken);
    return {
        ...lpStakingContracts
    }
};
