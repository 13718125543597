import {REACT_APP_UNIAPP_URL, SRK, SRKETHUNIV2} from "../../../constants";
import Web3Instance from "../../../Web3Instance";
import {buildTxUrl, dateToEpoch, epochToDate, onWrongNetwork} from "../../../helpers";
import {JSBI, Pair, TokenAmount} from "@uniswap/sdk";
import {BigNumber} from "ethers";

export class LiquidityStakingRewards {
    initialized = false;

    constructor(contractData, options) {
        this.init(contractData, options).then(r => {
        });
    }

    async init(contractData, options) {
        if (this.initialized) {
            return this.contract;
        }
        try {
            this.web3Instance = await Web3Instance.init();
            this.initialized = true;
            this.web3 = await Web3Instance.getRawWeb3();
            this.contract = await this.initContract(contractData);
            this.chainId = await this.web3Instance.getChainID();
            this.rewardsToken = options.rewardsToken[this.chainId];
            this.lpTokenInstance = options.lpTokenInstance;
            this.pairToken = (typeof options.pairToken === 'object') ?
                options.pairToken[this.chainId].address :
                options.pairToken;
            this.stakingToken = options.stakingToken[this.chainId];
            this.dexUrl = options.dexUrl;
            this.periodFinish = await this.contract.methods.periodFinish().call();
        } catch (e) {
            onWrongNetwork();
        }

        return this.contract;
    }

    async initContract(contractData) {
        return new this.web3.eth.Contract(contractData.abi, contractData.address);
    }

    async getSwapUrl() {
        return this.dexUrl
            + '/#/swap?inputCurrency='
            + this.pairToken
            + '&outputCurrency='
            + this.rewardsToken.address
    }

    async getLiquidityUrl(action = 'add') {
        if (!this.initialized) {
            await this.init()
        }

        return this.dexUrl
            + `/#/${action}/V2/`
            + this.rewardsToken.address
            + '/'
            + this.pairToken;
    }

    async getAddress() {
        return await this.contract.options.address;
    }

    async getRewardRate(toTokenAmount = false) {
        let amount = await this.contract.methods.rewardRate().call();
        let tokenAmount = new TokenAmount(this.rewardsToken, amount);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getTotalRewardRate(toTokenAmount = false) {
        let amount = await this.contract.methods.rewardRate().call() * JSBI.BigInt(60 * 60 * 24 * 7);
        let tokenAmount = new TokenAmount(this.rewardsToken, amount);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return parseInt(tokenAmount.toSignificant(15));
    }

    async getTotalSupply(toTokenAmount = false) {
        let amount = await this.contract.methods.totalSupply().call();
        let tokenAmount = new TokenAmount(this.stakingToken, amount);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async stake(address, amount) {
        amount = this.web3.utils.toWei(amount.toString());

        let txData = await this.contract.methods.stake(amount).send({from: address});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async stakeWithPermit(address, amount, deadline, v, r, s) {
        amount = this.web3.utils.toWei(amount);
        amount = new TokenAmount(this.stakingToken, amount);
        amount = `0x${amount.raw.toString(16)}`;
        let txData = await this.contract.methods.stakeWithPermit(
            amount,
            deadline,
            v,
            r,
            s
        ).send({from: address});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async balanceOf(address, toTokenAmount = false) {
        let bal = await this.contract.methods.balanceOf(address).call()
        let tokenAmount = new TokenAmount(this.stakingToken, bal);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getStakedTokens(address, toTokenAmount = false) {
        return await this.balanceOf(address, toTokenAmount);
    }

    async getEarnedTokens(address, toTokenAmount = false) {
        let earned = await this.contract.methods.earned(address).call();

        if (toTokenAmount) {
            return new TokenAmount(this.rewardsToken, earned);
        }

        return earned;
    }

    async getHypotheticalRewardRate(
        address,
        token0,
        token1,
        stakeAmount = null
    ) {
        const dummyPair = new Pair(new TokenAmount(token0, '0'), new TokenAmount(token1, '0'));

        let stakedAmount = stakeAmount ?? await this.getStakedTokens(address, true);
        const totalStakedAmount = new TokenAmount(
            dummyPair.liquidityToken, JSBI.BigInt((await this.getTotalSupply(true)).raw));
        // const rewardRate = await this.getRewardRate();
        const totalRewardRate = await this.getRewardRate(true);

        return new TokenAmount(
            this.rewardsToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
                : JSBI.BigInt(0)
        )?.multiply(`${60 * 60 * 24 * 7}`)
            ?.toSignificant(4)
    }

    async withdraw(address) {
        let txData = await this.contract.methods.exit().send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async claim(address) {
        let txData = await this.contract.methods.getReward().send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    getPeriodFinish() {
        return parseFloat(this.periodFinish);
    }

    getEndDate() {
        return epochToDate(this.periodFinish);
    }

    getRemainingDays() {
        let now = (new Date()).getTime();
        let diff = this.getEndDate().getTime() - now;
        let remDays = parseInt(diff / (1000 * 60 * 60 * 24) + '');

        if (remDays === 0) {
            return `${diff / (1000 * 60)} Minutes`;
        }
        return `${remDays} ${(remDays > 1 ? 'Days' : 'Day')}`;
    }

    hasStarted() {
        return this.periodFinish > dateToEpoch(new Date());
    }

    hasEnded() {
        if (this.periodFinish === '0') {
            return false;
        }
        return this.periodFinish <= dateToEpoch(new Date());
    }

    async apy(stakingTokenPriceinETH = 1, rewardsTokenPriceinETH = 1) {
        let EPOCH_PER_YEAR = 31556926; // Epoch per year
        let rewardForDuration = await this.getRewardForDuration();
        let duration = await this.contract.methods.rewardsDuration().call();

        let totalStaked = await this.getTotalSupply();
        let totalRewardsPerYear = rewardForDuration * (EPOCH_PER_YEAR / duration);

        let totalRewardPricePerYear = rewardsTokenPriceinETH * totalRewardsPerYear;
        let totalStakingTokensInPool = stakingTokenPriceinETH * totalStaked;

        return (totalRewardPricePerYear / totalStakingTokensInPool) * 100;
    }

    async getTotalLP(toDecimal = false, toTokenAmount = false) {
        let balance = await this.lpTokenInstance.totalSupply();
        if (toDecimal) {
            balance = this.web3.utils.fromWei(balance);
        }
        let tokenAmount = new TokenAmount(this.stakingToken, balance);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async getRewardForDuration(toDecimal = false, toTokenAmount = false) {
        let balance = await this.contract.methods.getRewardForDuration().call();
        if (toDecimal) {
            balance = this.web3.utils.fromWei(balance);
        }
        let tokenAmount = new TokenAmount(this.rewardsToken, balance);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }
}
