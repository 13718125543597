import {ChainId} from "@uniswap/sdk";

export const ETH_LP_PAIR_INFO = {
    "SRK-ETH-V1": {
        TITLE: "Stake SRK-ETH UNI-V2 and Earn SRK",
        TOKEN1: 'WETH',
        TOKEN2: 'SRK',
        STAKE_TYPE: "SRK-ETH UNI-V2",
        REWARD_TYPE: "SRK",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Ethereum Blockchain',
        SUPPORTED_NETWORK_ID: ChainId.MAINNET,
        EXPLORER: 'Etherscan',
    },
    "SRK-USDT-V1": {
        TITLE: "Stake SRK-USDT UNI-V2 and Earn SRK",
        TOKEN1: 'USDT',
        TOKEN2: 'SRK',
        STAKE_TYPE: "SRK-USDT UNI-V2",
        REWARD_TYPE: "SRK",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Ethereum Blockchain',
        SUPPORTED_NETWORK_ID: ChainId.MAINNET,
        EXPLORER: 'Etherscan',
    },
    "SRK-ETH-V2": {
        TITLE: "Stake SRK-ETH UNI-V2 and Earn SRK",
        TOKEN1: 'WETH',
        TOKEN2: 'SRK',
        STAKE_TYPE: "SRK-ETH UNI-V2",
        REWARD_TYPE: "SRK",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Ethereum Blockchain',
        SUPPORTED_NETWORK_ID: ChainId.MAINNET,
        EXPLORER: 'Etherscan',
    },
    "SRK-ETH-V3": {
        TITLE: "Stake SRK-ETH UNI-V2 and Earn SRK",
        TOKEN1: 'WETH',
        TOKEN2: 'SRK',
        STAKE_TYPE: "SRK-ETH UNI-V2",
        REWARD_TYPE: "SRK",
        IS_ACTIVE: true,
        BLOCKCHAIN: 'Ethereum Blockchain',
        SUPPORTED_NETWORK_ID: ChainId.MAINNET,
        EXPLORER: 'Etherscan',
    },
};
