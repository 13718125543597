import token from "../Token";
import {WETH, Token} from '@uniswap/sdk'
import {splitSignature} from "ethers/lib/utils";
import Web3Instance from "../../../Web3Instance";
import {SRK} from "../../../constants";

class UniV2Pair extends token {
    constructor(contractData, token0 = SRK, token1 = WETH) {
        super();
        if (this.initialized) {
            return;
        }
        this.contractData = contractData;
        this._token0 = token0;
        this._token1 = token1;
    }

    async init() {
        if (this.initialized) {
            return;
        }
        await Web3Instance.init();

        this.web3 = await Web3Instance.getRawWeb3();
        this.contract = await Web3Instance.initContract(this.contractData);
        this.raw = this.contract;
        this.token = new Token(
            Web3Instance.chainId, this.getAddress(), this.getDecimals(), this.getSymbol(), this.getName()
        );
        this.token0_ = this._token0[Web3Instance.chainId];
        this.token1_ = this._token1[Web3Instance.chainId];
        this.initialized = true;
    }

    getDeadline() {
        let deadline = new Date(); // get current date
        deadline.setHours(deadline.getHours() + 48); // after 48 hours
        return parseInt(deadline.getTime() / 1000); // in seconds
    }

    async permit(owner, spender, value) {
        await this.init();

        // try to gather a signature for permission
        value = this.web3.utils.toWei(value);
        const nonce = this.web3.utils.toHex(await this.getNonces(owner));

        const EIP712Domain = [
            {name: 'name', type: 'string'},
            {name: 'version', type: 'string'},
            {name: 'chainId', type: 'uint256'},
            {name: 'verifyingContract', type: 'address'}
        ];
        const domain = {
            name: 'Uniswap V2',
            version: '1',
            chainId: Web3Instance.chainId,
            verifyingContract: this.getAddress()
        };
        const Permit = [
            {name: 'owner', type: 'address'},
            {name: 'spender', type: 'address'},
            {name: 'value', type: 'uint256'},
            {name: 'nonce', type: 'uint256'},
            {name: 'deadline', type: 'uint256'}
        ];
        const message = {
            owner: owner,
            spender: spender,
            value: value,
            nonce: nonce,
            deadline: this.getDeadline()
        };
        const data = JSON.stringify({
            types: {
                EIP712Domain,
                Permit
            },
            domain,
            primaryType: 'Permit',
            message
        });

        let signature = await this.web3.currentProvider
            .send('eth_signTypedData_v4', [owner, data]);

        signature = splitSignature(signature.result);

        return {
            isSet: true,
            v: signature.v,
            r: signature.r,
            s: signature.s,
            deadline: message.deadline
        }
    }

    async token0(addressOnly = true) {
        await this.init();

        let tokenAddress = await this.contract.methods.token0().call();

        if (addressOnly) {
            return tokenAddress;
        }

        return this.token0_
    }

    async token1(addressOnly = true) {
        await this.init();

        let tokenAddress = await this.contract.methods.token1().call();

        if (addressOnly) {
            return tokenAddress;
        }

        return this.token1_;
    }
}

export default UniV2Pair;
