import genericToken from '../../assets/images/coin_empty.png';
import srkImg from '../../assets/images/coin_sparkpoint.png';
import ethImg from '../../assets/images/coin_ethereum.png';
import sfuelImg from '../../assets/images/sparkdefi.png';
import bnbImg from '../../assets/images/coin_bnb.png';
import usdtImg from '../../assets/images/coin_tether.svg';
import busdImg from '../../assets/images/coin_busd.png';
import chimomImg from '../../assets/images/coin_chimom.png';
import bcpImg from '../../assets/images/coin_bcp.svg';
import airxImg from '../../assets/images/coin_airx.png';
import figImg from '../../assets/images/coin_fig.png';
import kgoImg from '../../assets/images/coin_kgo.png';
import ownImg from '../../assets/images/coin_own.png';
import kclpImg from '../../assets/images/coin_kclp.png';

export const getTokenIcon = abbrv => {
    const tokenIcon = {
        altText: '',
        img: null
    }

    switch (abbrv) {
        case 'WETH':
            tokenIcon.altText = 'WETH';
            tokenIcon.img = genericToken;
            break;
        case 'BNB':
            tokenIcon.altText = 'BNB';
            tokenIcon.img = bnbImg;
            break;
        case 'SFUEL':
            tokenIcon.altText = 'SFUEL';
            tokenIcon.img = sfuelImg;
            break;
        case 'USDT':
            tokenIcon.altText = 'USDT';
            tokenIcon.img = usdtImg;
            break;
        case 'BUSD':
            tokenIcon.altText = 'BUSD';
            tokenIcon.img = busdImg;
            break;
        case 'ETH':
            tokenIcon.altText = 'Ethereum';
            tokenIcon.img = ethImg;
            break;
        case 'CHIMOM':
            tokenIcon.altText = 'CHIMOM';
            tokenIcon.img = chimomImg;
            break;
        case 'BCP':
            tokenIcon.altText = 'BCP';
            tokenIcon.img = bcpImg;
            break;
        case 'AIRx':
            tokenIcon.altText = 'AIRx';
            tokenIcon.img = airxImg;
            break;
        case 'FiG':
            tokenIcon.altText = 'FiG';
            tokenIcon.img = figImg;
            break;
        case 'KGO':
            tokenIcon.altText = 'KGO';
            tokenIcon.img = kgoImg;
            break;
        case 'OWN':
            tokenIcon.altText = 'OWN';
            tokenIcon.img = ownImg;
            break;
        case 'KCLP':
            tokenIcon.altText = 'KCLP';
            tokenIcon.img = kclpImg;
            break;
        default:
            tokenIcon.altText = 'SparkPoint';
            tokenIcon.img = srkImg;
    }

    return tokenIcon;
}
