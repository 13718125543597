import BsrkBnbStakingRewardsABI from '../abis/BsrkBnbStakingRewards.json'
import {BSRK, BSRKBNB_CAKELP, REACT_APP_PANCAKEAPP_URL} from '../../../../constants';
import StakingRewards from "../../../../eth/staking/liquidity/StakingRewards";

class BsrkBnbStakingRewards extends StakingRewards {
    initialized = false;

    constructor() {
        super(BsrkBnbStakingRewardsABI, {
            rewardsToken: BSRK,
            pairToken: 'ETH',
            stakingToken: BSRKBNB_CAKELP,
            dexUrl: REACT_APP_PANCAKEAPP_URL
        });
    }

};

export default BsrkBnbStakingRewards;
