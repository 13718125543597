import PoolBasedStakingV1 from "./PoolBasedStakingV1";
import {TokenAmount} from "@uniswap/sdk";

class PoolBasedStakingV2 extends PoolBasedStakingV1{

    async getTotalDeposits(toDecimal = false, toTokenAmount = false) {
        let balance = await this.contract.methods.totalDeposit().call();
        if (toDecimal) {
            balance = this.web3.utils.fromWei(balance);
        }
        let tokenAmount = new TokenAmount(this.stakingToken, balance);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

}

export default PoolBasedStakingV2;
