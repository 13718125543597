import React from 'react';

const Box = ({
    children, boxWidth = '100', boxHeight = 'box-height-1', boxStyle='style-1', rounded='rounded-0', tablePadding = '',
    className = ''
  }) => (
  <div className="box-container" style={{width: `${boxWidth}%`}}>
    <div className={`${className} box ${boxStyle} ${rounded} ${tablePadding} ${boxHeight}`}>
      {children}
    </div>
  </div>
);

export default Box;
