import React from 'react';
import Layout from '../layout/';

export const withLayout = otherProps => (WrappedComponent) => {
    const WrappedPages = props => (
        <Layout
            {...props} {...otherProps}
        >
            <WrappedComponent {...props} {...otherProps} />
        </Layout>
    );

    return WrappedPages;
};
