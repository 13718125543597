import {BINANCE_SMART_CHAIN} from "../../constants";

export const getBscPooltakingCardDetails = (blockchain, staking) => {
    const setCardDetails = (stakingData,
            onRightNetwork = true,
            rewardsToken = 'SRKb',
            stakingToken = 'SFUEL',
            category = 'SRKb-SFUEL',
            versions = ['V1'],
            upcoming = false
    ) => {
        if (!stakingData) {
            stakingData = {
                totalDeposits: '0.0000',
                APR: '0%',
                rewardRate: '0',
                hasEnded: false
            };
        }
        return {
            totalDeposits: parseFloat(stakingData.totalDeposits).toFixed(5),
            APR: stakingData.APR,
            rewardRate: parseFloat(stakingData.rewardRate).toFixed(5),
            hasEnded: stakingData.hasEnded,
            onRightNetwork: onRightNetwork,
            category: category,
            versions: versions,
            contract: stakingData.contract,
            remainingDays: stakingData.remainingDays,
            upcoming: upcoming
        };
    }

    let stakingData = {};

    stakingData.srkSfuelPool = setCardDetails(
        null,
        false,
        'SFUEL',
        'SRKb',
        'SRKb-SFUEL',
        ['V1']
    );
    stakingData.sfuelSfuelPool = setCardDetails(
        null,
        false,
        'SFUEL',
        'SFUEL',
        'SFUEL-SFUEL',
        ['V1']
    );
    stakingData.srkbSrkbPool = setCardDetails(
        null,
        false,
        'SRKb',
        'SRKb',
        'SRKb-SRKb',
        ['V1']
    );
    stakingData.srkbBcpPool = setCardDetails(
        null,
        false,
        'BCP',
        'SRKb',
        'SRKb-BCP',
        ['V1']
    );
    stakingData.bcpSfuelPool = setCardDetails(
        null,
        false,
        'SFUEL',
        'BCP',
        'BCP-SFUEL',
        ['V1']
    );
    stakingData.srkbAirxPool = setCardDetails(
        null,
        false,
        'AIRx',
        'SRKb',
        'SRKb-AIRx',
        ['V1']
    );
    stakingData.srkbFigPool = setCardDetails(
        null,
        false,
        'FIG',
        'SRKb',
        'SRKb-FiG',
        ['V1']
    );
    stakingData.kgoSrkbPool = setCardDetails(
        null,
        false,
        'SRKb',
        'KGO',
        'KGO-SRKb',
        ['V1']
    );
    stakingData.srkbKgoPool = setCardDetails(
        null,
        false,
        'KGO',
        'SRKb',
        'SRKb-KGO',
        ['V1']
    );
    stakingData.srkbOwnPool = setCardDetails(
        null,
        false,
        'OWN',
        'SRKb',
        'SRKb-OWN',
        ['V1']
    );
    stakingData.ownOwnPool = setCardDetails(
        null,
        false,
        'OWN',
        'OWN',
        'OWN-OWN',
        ['V1']
    );
    stakingData.srkbKclpPool = setCardDetails(
        null,
        false,
        'KCLP',
        'SRKb',
        'SRKb-KCLP',
        ['V1']
    );
    stakingData.kclpSrkbPool = setCardDetails(
        null,
        false,
        'SRKb',
        'KCLP',
        'KCLP-SRKb',
        ['V1']
    );
    if (blockchain === BINANCE_SMART_CHAIN) {

        stakingData.srkSfuelPool = setCardDetails(
            staking.srkbSfuelLPoolV1,
            true,
            'SFUEL',
            'SRKb',
            'SRKb-SFUEL',
            ['V1']
        );
        stakingData.sfuelSfuelPool = setCardDetails(
            staking.sfuelSfuelLPoolV1,
            true,
            'SFUEL',
            'SFUEL',
            'SFUEL-SFUEL',
            ['V1']
        );
        stakingData.srkbSrkbPool = setCardDetails(
            staking.srkbSrkbLPoolV1,
            true,
            'SRKb',
            'SRKb',
            'SRKb-SRKb',
            ['V1']
        );
        stakingData.srkbBcpPool = setCardDetails(
            staking.srkbBcpLPoolV1,
            true,
            'BCP',
            'SRKb',
            'SRKb-BCP',
            ['V1']
        );
        stakingData.bcpSfuelPool = setCardDetails(
            staking.bcpSfuelLPoolV1,
            true,
            'SFUEL',
            'BCP',
            'BCP-SFUEL',
            ['V1']
        );
        stakingData.srkbAirxPool = setCardDetails(
            staking.srkbAirxLPoolV1,
            true,
            'AIRx',
            'SRKb',
            'SRKb-AIRx',
            ['V1']
        );
        stakingData.srkbFigPool = setCardDetails(
            staking.srkbFigLPoolV1,
            true,
            'FIG',
            'SRKb',
            'SRKb-FiG',
            ['V1']
        );
        stakingData.kgoSrkbPool = setCardDetails(
            staking.kgoSrkbLPoolV1,
            true,
            'SRKb',
            'KGO',
            'KGO-SRKb',
            ['V1']
        );
        stakingData.srkbKgoPool = setCardDetails(
            staking.srkbKgoLPoolV1,
            true,
            'KGO',
            'SRKb',
            'SRKb-KGO',
            ['V1']
        );
        stakingData.srkbOwnPool = setCardDetails(
            staking.srkbOwnLPoolV1,
            true,
            'OWN',
            'SRKb',
            'SRKb-OWN',
            ['V1'],
        );
        stakingData.ownOwnPool = setCardDetails(
            staking.ownOwnLPoolV1,
            true,
            'OWN',
            'OWN',
            'OWN-OWN',
            ['V1'],
        );
        stakingData.srkbKclpPool = setCardDetails(
            staking.srkbKclpLPoolV1,
            true,
            'KCLP',
            'SRKb',
            'SRKb-KCLP',
            ['V1'],
        );
        stakingData.kclpSrkbPool = setCardDetails(
            staking.kclpSrkbLPoolV1,
            true,
            'SRKb',
            'KCLP',
            'KCLP-SRKb',
            ['V1'],
        );
    }


    return stakingData
}
