import {liquidityStakingList} from "../liquidityStakingList";
import {LiquidityStakingRewards as EthLPStakingRewards} from "./LiquidityStakingRewards";
import {REACT_APP_UNIAPP_URL, SRK, SRKETHUNIV2} from "../../../constants";
import {SrkUsdtStakingRewards} from "./pairs";

let lpStakingContracts = {};

export const initEthLiquidityStakingContracts = async (tokens) => {
    // SRK-ETH
    liquidityStakingList.list['SRK-ETH'].map((address, index) => {
        lpStakingContracts['srkEthStakingRewardsV' + (index + 1)] = new EthLPStakingRewards(
            {
                address: address,
                abi: liquidityStakingList.abi
            },
            {
                rewardsToken: SRK,
                pairToken: 'ETH',
                stakingToken: SRKETHUNIV2,
                dexUrl: REACT_APP_UNIAPP_URL,
                lpTokenInstance: tokens.uniV2Token
            }
        );
    })
    lpStakingContracts.srkUsdtStakingRewards = new SrkUsdtStakingRewards();

    return {
        ...lpStakingContracts
    }

}
