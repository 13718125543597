import {AIRx, BCP, KGO, REACT_APP_SPARKSWAP_URL, SRKb} from '../../../../constants';
import PoolBasedStakingV2 from "../PoolBasedStakingV2";
import {buildTxUrl, expandValue} from "../../../../helpers";
import Web3Instance from "../../../../Web3Instance";
import {TokenAmount} from "@uniswap/sdk";

class KgoSrkbPool extends PoolBasedStakingV2 {
    initialized = false;

    constructor(contractData, stakeTokenInstance) {
        super(contractData, {
            stakeTokenInstance: stakeTokenInstance,
            rewardsToken: SRKb,
            stakingToken: KGO,
            pairToken: 'ETH',
            dexUrl: REACT_APP_SPARKSWAP_URL,
            rewardTokenDexUrl: 'https://sparkswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xC3440c10c4F36f354eB591B19FafB4906d449B75'
        });
    }

    async getMaxStake() {
        return (await this.contract.methods.maxStaking().call()) / Math.pow(10, 5);
    }

    async stake(address, amount) {

        amount = expandValue(this.web3, amount, 5)

        let txData = await this.contract.methods.enterStaking(amount.toString()).send({from: address});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async getTotalDeposits(toDecimal = false, toTokenAmount = false) {
        let balance = await this.contract.methods.totalDeposit().call();
        if (toDecimal) {
            balance = balance / Math.pow(10, 5);
        }
        let tokenAmount = new TokenAmount(this.stakingToken, balance);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async withdraw(address, amount) {
        amount = expandValue(this.web3, amount, 5)

        let txData = await this.contract.methods.leaveStaking(amount.toString()).send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }
}

export default KgoSrkbPool;
