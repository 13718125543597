import {FIG, REACT_APP_SPARKSWAP_URL, SRKb} from '../../../../constants';
import PoolBasedStakingV2 from "../PoolBasedStakingV2";

class SrkbFigPool extends PoolBasedStakingV2 {
    initialized = false;

    constructor(contractData, stakeTokenInstance) {
        super(contractData, {
            stakeTokenInstance: stakeTokenInstance,
            rewardsToken: FIG,
            stakingToken: SRKb,
            pairToken: 'ETH',
            dexUrl: REACT_APP_SPARKSWAP_URL,
            rewardTokenDexUrl: 'https://sparkswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xC3440c10c4F36f354eB591B19FafB4906d449B75'
        });
    }
}

export default SrkbFigPool;
