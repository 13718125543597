import Web3Instance from "../../../Web3Instance";
import {onWrongNetwork} from "../../../helpers";
import {LiquidityStakingRewards as LPStakingRewards} from "../../../eth/staking/liquidity/LiquidityStakingRewards"
import {REACT_APP_PANCAKEAPP_URL} from "../../../constants";

export class LiquidityStakingRewards extends LPStakingRewards {
    initialized = false;

    async init(contractData, options) {
        if (this.initialized) {
            return this.contract;
        }
        try {
            this.web3Instance = await Web3Instance.init();
            this.initialized = true;
            this.web3 = await Web3Instance.getRawWeb3();
            this.contract = await this.initContract(contractData);
            this.chainId = await this.web3Instance.getChainID();
            this.mainToken = options.mainToken[this.chainId];
            this.rewardsToken = options.rewardsToken[this.chainId];
            this.pairToken = (typeof options.pairToken === 'object') ?
                options.pairToken[this.chainId].address :
                options.pairToken;
            this.stakingToken = options.stakingToken[this.chainId];
            this.dexUrl = options.dexUrl;
            this.periodFinish = await this.contract.methods.periodFinish().call();
            this.lpTokenInstance = options.lpTokenInstance;
        } catch (e) {
            onWrongNetwork();
        }

        return this.contract;
    }

    async getSwapUrl() {
        if (this.dexUrl === REACT_APP_PANCAKEAPP_URL) {
            return this.dexUrl
                + 'swap?inputCurrency='
                + this.pairToken
                + '&outputCurrency='
                + this.rewardsToken.address
        }
        return this.dexUrl
            + '/#/swap?inputCurrency='
            + this.pairToken
            + '&outputCurrency='
            + this.rewardsToken.address
    }

    async getLiquidityUrl(action = 'add') {
        if (!this.initialized) {
            await this.init()
        }

        if (this.dexUrl === REACT_APP_PANCAKEAPP_URL) {
            return this.dexUrl
                + `add/`
                + this.mainToken.address
                + '/'
                + this.pairToken;
        }

        return this.dexUrl
            + `/#/add/`
            + this.mainToken.address
            + '/'
            + this.pairToken;
    }

    async getAddress() {
        return await this.contract.options.address;
    }

    async stakeWithPermit(address, amount, deadline, v, r, s) {
        return false;
    }

    async dynamicApySparkSwap() {
        try {
            let assets = await fetch("https://api.sparkswap.info/api/assets");
            assets = await assets.json();
            let summary = await fetch("https://api.sparkswap.info/api/summary");
            summary = await summary.json();

            let mainTokenChecksum = this.web3.utils.toChecksumAddress(this.mainToken.address);
            let pairTokenChecksum = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
            let rewardTokenChecksum = this.web3.utils.toChecksumAddress(this.rewardsToken.address);
            let pairTotalLiquidityBNB;

            if(this.pairToken != "ETH"){
                pairTokenChecksum = this.web3.utils.toChecksumAddress(this.pairToken)
            }


            if(summary.hasOwnProperty(mainTokenChecksum + "_" + pairTokenChecksum)){
                pairTotalLiquidityBNB = summary[mainTokenChecksum + "_" + pairTokenChecksum]["liquidity_BNB"];
            }
            else{
                pairTotalLiquidityBNB = summary[pairTokenChecksum + "_" + mainTokenChecksum]["liquidity_BNB"];
            }

            let totalLPSupply = await this.getTotalLP();
            let lpTokenValue = pairTotalLiquidityBNB / totalLPSupply;
            let rewardPrice = assets[rewardTokenChecksum]["last_price"];
            let bnbPrice = assets["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"]["last_price"];

            return await this.apy(lpTokenValue, rewardPrice / bnbPrice);
        } catch (e) {
            console.error(e)
            return 0;
        }
    }

    async apy(stakingTokenPriceinETH = 1, rewardsTokenPriceinETH = 1) {
        let EPOCH_PER_YEAR = 31556926; // Epoch per year
        let rewardForDuration = await this.getRewardForDuration();
        let duration = await this.contract.methods.rewardsDuration().call();

        let totalStaked = await this.getTotalSupply();
        let totalRewardsPerYear = rewardForDuration * (EPOCH_PER_YEAR / duration);

        let totalRewardPricePerYear = rewardsTokenPriceinETH * totalRewardsPerYear;
        let totalStakingTokensInPool = stakingTokenPriceinETH * totalStaked;

        return (totalRewardPricePerYear / totalStakingTokensInPool) * 100;
    }
}
