import {withRouter} from 'react-router-dom';
import StakeActionsPage from "../StakeActionsPage";

class SrkEthStakeActionsPage extends StakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.srk;
        this.lpTokenBal = props.wallet.balance.uniV2;

        this.lpTokenContract = props.contracts.uniV2Token;
        this.stakingRewardsContract = props.contracts['srkEthStakingRewards' + props.match.params.version];

        this.poolData = props.staking['srkEthPool' + props.match.params.version];
    }
}

export default withRouter(SrkEthStakeActionsPage);
