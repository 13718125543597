import {CONFIRMATION_DIALOG_STATUS} from "../../../blockchain/constants";
import StakeActionsPage from "../StakeActionsPage";

class BscStakeActionsPage extends StakeActionsPage {
    async handleConfirmApproveToken() {
        this.setState({
            'isApproveLoading': true,
            'modalHeaderTitle': '',
        });
        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            await this.lpTokenContract.approve(
                this.selectedAddress,
                await this.stakingRewardsContract.getAddress(),
                this.state.depositAmount
            );

            this.setState({
                'txUrl': '',
                'approvedTokens': parseFloat(this.state.depositAmount),
                'canDeposit': true,
                'isApproveDisabled': true,
                'confirmationDialogStatus': CONFIRMATION_DIALOG_STATUS.TRANSACTION_SUBMITTED,
                'isTransactionComplete': true,
                'approveButtonText': 'Approved',
                'isApproveLoading': false,
            });
        } catch (e) {
            this.setState({
                'isApproveLoading': false,
                'modalHeaderTitle': '',
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
            console.error(e);
        }
    }

    checkApproveStakeStatus(value) {
        value = parseFloat(value || '0');
        if ((value > 0 &&
            value <= this.state.approvedTokens &&
            value <= this.lpTokenBal)
        ) {
            this.setState({
                'canDeposit': true,
                'isApproveDisabled': true,
                'approveButtonText': 'Approved',
                'depositButtonText': 'Deposit'
            });
        } else {
            if (value > 0 && value > this.state.approvedTokens && value <= this.lpTokenBal) {
                this.setState({
                    'canDeposit': false,
                    'isApproveDisabled': false,
                    'approveButtonText': 'Approve',
                    'depositButtonText': 'Deposit'
                });
            } else {
                this.setState({
                    'canDeposit': false,
                    'isApproveDisabled': true,
                    'approveButtonText': 'Approve',
                    'depositButtonText': 'Deposit'
                });
            }
        }
    }

    async handleConfirmDepositToken() {
        this.setState({
            'isDepositLoading': true,
            'modalHeaderTitle': ''
        });
        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            const txData = await this.stakingRewardsContract.stake(
                this.selectedAddress,
                this.state.depositAmount,
            );

            this.setState({
                'txUrl': txData.txUrl,
                'approvedTokens': this.state.approvedTokens - this.state.depositAmount,
                'depositButtonText': 'Deposited',
                'isDepositLoading': false,
                'canDeposit': false
            });
            this.transactionSubmitted();
            await this.updateAccountInfo(true);
        } catch (e) {
            this.setState({
                'depositButtonText': 'Deposit',
                'isDepositLoading': false,
                'canDeposit': false
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
            console.error(e);
        }
    }
}

export default BscStakeActionsPage;
