import {REACT_APP_SPARKSWAP_URL, SRKb} from '../../../../constants';
import PoolBasedStakingV1 from "../PoolBasedStakingV1";
import { srkbsrkbpool } from "../../../../apollo/client";

class SrkbSrkbPool extends PoolBasedStakingV1 {
    initialized = false;

    constructor(contractData, stakeTokenInstance) {
        super(contractData, {
            stakeTokenInstance: stakeTokenInstance,
            rewardsToken: SRKb,
            stakingToken: SRKb,
            pairToken: 'ETH',
            dexUrl: REACT_APP_SPARKSWAP_URL,
            rewardTokenDexUrl: 'https://sparkswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xC3440c10c4F36f354eB591B19FafB4906d449B75',
            graphQL: srkbsrkbpool
        });
    }
}

export default SrkbSrkbPool;
