import {BINANCE_SMART_CHAIN} from "./bsc/constants";
import {onWrongNetwork} from "./helpers";
import {updateBscLiquidityStakingData} from "./bsc/staking/liquidity/stakingDetails";
import {updateLaunchPoolData} from "./bsc/staking/pool/stakingDetails";
import {updateEthereumLPStakingData} from "./eth/staking/liquidity/stakingDetails";

let selectedWalletAddress = null;
let stakingData = {
    lp: {},
    pool: {}
};

/*------------------------ POOL-BASED STAKING */

export const updateStakingBalances = async (blockchain, contracts, address) => {
    try {
        selectedWalletAddress = address;

        if (blockchain !== BINANCE_SMART_CHAIN) {
            stakingData.lp = await updateEthereumLPStakingData(selectedWalletAddress, contracts);
        } else {
            stakingData.lp = await updateBscLiquidityStakingData(selectedWalletAddress, contracts);
            stakingData.pool = await updateLaunchPoolData(selectedWalletAddress, contracts);
        }
    } catch (e) {
        console.log(e);
        onWrongNetwork();
    }

    return {
        ...stakingData.lp,
        ...stakingData.pool
    };
};
