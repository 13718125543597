export const poolStakingV2List = {
    list: {
        'SRKb-BCP': [
            '0x37e949B1a4889a0501478f83d7AFc609d7f909F0',
        ],
        'BCP-SFUEL': [
            '0x3d0BEa7C9e072F8A29420622B17B098D34c3b54E',
        ],
        'SRKb-AIRx': [
            '0x1FC5Aa1Ed0E6DCb81BA57A1F30661422eB708150',
        ],
        'SRKb-FIG': [
            '0x76f47477e7ff9f4463d1b84156ce4dc23a64bc4d',
        ],
        'KGO-SRKb': [
            '0x84b9E1574bD7D99639B3d6f822312f2Bdf9550af',
        ],
        'SRKb-KGO': [
            '0xE1856Feb9102157fc658B03dD1fddE9a194A5d37',
        ],
        'SRKb-OWN': [
            '0xDBfaE4a8b3b707f72cE7b6FE621c7C2Ca8540AB9',
        ],
        'OWN-OWN': [
            '0xccef477b3c2f12e5883073EF480C2F5aB9e82149',
        ],
        'SRKb-KCLP': [
            '0x915b95fE12F22e5dB289991Ef59bc5AC384ADf5C',
        ],
        'KCLP-SRKb': [
            '0xE40Eb6d59646316487D222dD0E355bf81dCCfCf5',
        ],
    },
    abi: [
        {
            "inputs":[
                {
                    "internalType":"contract IBEP20",
                    "name":"_stakingToken",
                    "type":"address"
                },
                {
                    "internalType":"contract IBEP20",
                    "name":"_rewardToken",
                    "type":"address"
                },
                {
                    "internalType":"uint256",
                    "name":"_rewardPerBlock",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"_startBlock",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"_bonusEndBlock",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"_computationSensitivity",
                    "type":"uint256"
                }
            ],
            "stateMutability":"nonpayable",
            "type":"constructor"
        },
        {
            "anonymous":false,
            "inputs":[
                {
                    "indexed":true,
                    "internalType":"address",
                    "name":"user",
                    "type":"address"
                },
                {
                    "indexed":false,
                    "internalType":"uint256",
                    "name":"amount",
                    "type":"uint256"
                }
            ],
            "name":"Deposit",
            "type":"event"
        },
        {
            "anonymous":false,
            "inputs":[
                {
                    "indexed":true,
                    "internalType":"address",
                    "name":"user",
                    "type":"address"
                },
                {
                    "indexed":false,
                    "internalType":"uint256",
                    "name":"amount",
                    "type":"uint256"
                }
            ],
            "name":"EmergencyWithdraw",
            "type":"event"
        },
        {
            "anonymous":false,
            "inputs":[
                {
                    "indexed":true,
                    "internalType":"address",
                    "name":"previousOwner",
                    "type":"address"
                },
                {
                    "indexed":true,
                    "internalType":"address",
                    "name":"newOwner",
                    "type":"address"
                }
            ],
            "name":"OwnershipTransferred",
            "type":"event"
        },
        {
            "anonymous":false,
            "inputs":[
                {
                    "indexed":true,
                    "internalType":"address",
                    "name":"user",
                    "type":"address"
                },
                {
                    "indexed":false,
                    "internalType":"uint256",
                    "name":"amount",
                    "type":"uint256"
                }
            ],
            "name":"Withdraw",
            "type":"event"
        },
        {
            "inputs":[

            ],
            "name":"bonusEndBlock",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"computationSensitivity",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"uint256",
                    "name":"_amount",
                    "type":"uint256"
                }
            ],
            "name":"emergencyRewardWithdraw",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"emergencyWithdraw",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"uint256",
                    "name":"_amount",
                    "type":"uint256"
                }
            ],
            "name":"enterStaking",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"uint256",
                    "name":"_from",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"_to",
                    "type":"uint256"
                }
            ],
            "name":"getMultiplier",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"uint256",
                    "name":"_amount",
                    "type":"uint256"
                }
            ],
            "name":"leaveStaking",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"maxStaking",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"owner",
            "outputs":[
                {
                    "internalType":"address",
                    "name":"",
                    "type":"address"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"address",
                    "name":"_user",
                    "type":"address"
                }
            ],
            "name":"pendingReward",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"poolInfo",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"lastRewardBlock",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"accRewardPerShare",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"renounceOwnership",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"rewardPerBlock",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"rewardToken",
            "outputs":[
                {
                    "internalType":"contract IBEP20",
                    "name":"",
                    "type":"address"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"stakingToken",
            "outputs":[
                {
                    "internalType":"contract IBEP20",
                    "name":"",
                    "type":"address"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"startBlock",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"stopReward",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"totalDeposit",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"address",
                    "name":"newOwner",
                    "type":"address"
                }
            ],
            "name":"transferOwnership",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[

            ],
            "name":"updatePool",
            "outputs":[

            ],
            "stateMutability":"nonpayable",
            "type":"function"
        },
        {
            "inputs":[
                {
                    "internalType":"address",
                    "name":"",
                    "type":"address"
                }
            ],
            "name":"userInfo",
            "outputs":[
                {
                    "internalType":"uint256",
                    "name":"amount",
                    "type":"uint256"
                },
                {
                    "internalType":"uint256",
                    "name":"rewardDebt",
                    "type":"uint256"
                }
            ],
            "stateMutability":"view",
            "type":"function"
        }
    ]
}
