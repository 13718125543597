import {setLPStakingData} from "../../../helpers";

export const updateEthereumLPStakingData = async (
    selectedWalletAddress,
    contracts
) => {
    let stakingDetails = {};
    stakingDetails.srkEthPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkEthStakingRewardsV1,
        await contracts.uniV2Token.token0(false),
        await contracts.uniV2Token.token1(false)
    );
    stakingDetails.srkEthPoolV2 = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkEthStakingRewardsV2,
        await contracts.uniV2Token.token0(false),
        await contracts.uniV2Token.token1(false)
    );
    stakingDetails.srkEthPoolV3 = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkEthStakingRewardsV3,
        await contracts.uniV2Token.token0(false),
        await contracts.uniV2Token.token1(false),
        0,
        await contracts.srkEthStakingRewardsV3.apy((677.35 / 167782.970790470851722593), 0.000002) // Pricing as of June 4, TODO: Add dynamic pricing fetching for Uni LP tokens
    );
    stakingDetails.srkUsdtPool = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkUsdtStakingRewards,
        await contracts.srkUsdtUniV2Token.token0(false),
        await contracts.srkUsdtUniV2Token.token1(false)
    );

    return stakingDetails;
}
