import React from "react";
import {Alert, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {POOLBASED_SETTINGS} from "../blockchain/constants";

export const Notice = ({category}) => (
    <>
        {
            (!POOLBASED_SETTINGS.isDepositEnabled || !POOLBASED_SETTINGS.isWithdrawalEnabled) &&
                POOLBASED_SETTINGS.categories.includes(category)
            &&

            <Row className="py-0 mt-3 mb-0 mb-md-0">
                <Col>
                    <Alert variant={'warning'} className={'d-block mb-0'}>
                        <FontAwesomeIcon icon={faInfoCircle} className={'text-warning'}/> SRKb and SFUEL Withdrawals and Deposits are
                        locked for 48 hours during launchpad
                    </Alert>
                </Col>
            </Row>
        }
    </>
)
