import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Box from '../../../components/Box';
import {getTokenIcon} from '../../../blockchain/constants/tokenIcons';
import Heading from "../../../components/Heading";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWaveSquare} from "@fortawesome/free-solid-svg-icons";
import SplitButton from "./SplitButton";
import numeral from "numeral";

const StakeBox = ({type, wallet, data}) => {
    const token1 = getTokenIcon(type.TOKEN1);
    const token2 = getTokenIcon(type.TOKEN2);

    const [APR, setAPR] = useState('-');

    useEffect(() => {
        let mounted = true;
        if (data.contract && data.contract.dynamicApySparkSwap) {
            if(data.contract.stakingToken.address == "0x9F6B80e3867AB402081574e9E0a3BE6fdf4ae95b"){
                data.contract.apy(291.85/280784.76694422006466245,0.000093).then(r => {
                    if (mounted) {
                        setAPR(r);
                    }
                });
            }
            else{
                data.contract.dynamicApySparkSwap().then(r => {
                    if (mounted) {
                        setAPR(r);
                    }
                });
            }
        }
        return function cleanup() {
            mounted = false;   // clean up function
        };
    }, []);
    let btnTxt;
    let stakingEnd = '';
    let btnClassNames = 'blue text-white';
    if (type.IS_ACTIVE) {
        if (!data.onRightNetwork) {
            btnTxt = <>
                <FontAwesomeIcon icon={faWaveSquare} size={'1x'} className={'text-white'}/>&nbsp; Wrong Network</>;
            btnClassNames = 'bg-danger text-white';
        } else {
            btnTxt = data.hasEnded ? 'Withdraw' : 'Deposit';
            if (!data.hasEnded && data.remainingDays) {
                stakingEnd = `(Ends in ${(data.remainingDays)})`;
            }
        }
    } else {
        btnTxt = 'Coming Soon';
    }

    let hasEndedText = data.hasEnded ? ' (Ended)' : '';

    return (
        <Box boxWidth={100} boxStyle={'style-2'} rounded={'rounded-3'}>
            <div className="flexColumn">
                <Heading
                    heading={type.TITLE + hasEndedText}
                    headingSize={'heading-4'}
                    headingColor={'#3cbbe8'}
                    subheading={`${type.STAKE_TYPE} Pool ${stakingEnd}`}
                    // subheadingSize={'subheading-1'}
                    subheadingColor={'#fff'}
                    token1={token1}
                    token2={token2}
                />
                <Row className="px-3" style={{flexGrow: 1}}>
                    <Col xs={12} className="m-0 p-0">
                        <table className="content-table w-100 mx-auto">
                            <tbody>
                            <tr>
                                <td className="left text-center">Total Deposits:</td>
                                <td className="right">{data.liquidityDepositsTotal}</td>
                            </tr>
                            <tr>
                                <td className="left text-center">Pool Rate:</td>
                                <td className="right">{data.poolRate}</td>
                            </tr>
                            <tr>
                                <td className="left text-center">APR:</td>
                                <td className="right">{numeral(APR).format('0,0.00')} %</td>
                            </tr>
                            <tr className="border-top">
                                <td className="left text-center">Your rate:</td>
                                <td className="right">{data.rewardRate}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col xs={12}>
                        <SplitButton
                            wallet={wallet}
                            data={data}
                            btnTxt={btnTxt}
                            btnClassNames={btnClassNames}
                        />
                    </Col>
                </Row>
            </div>
        </Box>
    );
}

export default StakeBox;
