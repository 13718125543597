import React, {Fragment} from 'react';

const Button = ({
                    id = 'btn-id',
                    handleClick = () => {
                    },
                    text = 'button',
                    disabled = false, isLoading = false,
                    className = 'blue button size-1 button-height-1',
                    display = '',
                    style = {}
                }) => {

    const showLoader = () => (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );

    const showContent = text => (
        <Fragment>
            {text}
        </Fragment>
    );

    return (
        <button type='button'
                id={id}
                className={`${className} ${display}`}
                onClick={handleClick}
                disabled={disabled}
                style={style}
        >
            {
                isLoading ? showLoader() : showContent(text)
            }
        </button>
    );
}

export default Button;
