import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useHistory} from "react-router-dom";

export default function SplitButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory();

    const handleClick = (version) => {
        history.push(`/stake/liquidity/${props.data.category}/${version}`);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button disabled={(!props.data.onRightNetwork || !props.wallet.connected) && true}
                            size="large"
                            className={props.btnClassNames}
                            onClick={() => handleClick(props.data.versions[props.data.versions.length - 1])}
                    >
                        {props.btnTxt}
                    </Button>
                    {props.data.versions.length > 1 && <Button
                        disabled={(!props.data.onRightNetwork || !props.wallet.connected) && true}
                        color="primary"
                        size="small"
                        className="blue"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon/>
                    </Button>}
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({TransitionProps, placement}) => (
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {props.data.versions.map((version, index) => (
                                        <MenuItem
                                            key={version}
                                            onClick={(event) => handleClick(version)}
                                        >
                                            Withdraw ({version})
                                        </MenuItem>
                                    )).filter((version, index) => {
                                        return (props.data.versions.length - 1) !== index
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}
