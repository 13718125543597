import SRKABI from "./SparkPoint.json";
import USDTABI from "./TetherToken.json";

import SRKETHABI from "./lp/SrkEthUniswapV2Pair.json";
import SRKUSDTABI from "./lp/SrkUsdtUniswapV2Pair.json";

export const SRK_ABI = SRKABI;
export const USDT_ABI = USDTABI;

export const SRK_ETH_UNI_ABI = SRKETHABI;
export const SRK_USDT_UNI_ABI = SRKUSDTABI;
