import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Heading from '../../components/Heading';

const AssetsManagementPage = () => {
    return (
        <div className="assets-management-page">
            <Row className="px-3 py-0 mx-0 mx-md-5 mt-5 mb-4 mb-md-5">
                <Col xs={12} className="p-0 m-0">
                    <Heading heading={'Manage DeFi Assets (Coming Soon)'}/>
                </Col>
            </Row>
        </div>
    );
}

export default AssetsManagementPage;
