import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class FigBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.sfuel;
        this.lpTokenBal = props.wallet.balance.figBnbSparkLP;

        this.lpTokenContract = props.contracts.figBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['figBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['figBnbPool' + props.match.params.version];
    }
}

export default withRouter(FigBnbStakeActionsPage);
