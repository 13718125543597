import React from 'react';
import { useMedia } from 'react-use';
import logo from '../../assets/images/spark-defi-logo.png';
import {NavLink} from "react-router-dom";
import stake from "../../assets/images/stake.png";
import pool from "../../assets/images/pool.png";
import lend from "../../assets/images/lend.png";
import manage from "../../assets/images/manage.png";
import swap from "../../assets/images/swap.png";
import bridge from "../../assets/images/bridge.png";
import vote from "../../assets/images/vote.png";
import Logo from "../../assets/images/PoweredBySP.svg"
import LogoMobile from "../../assets/images/PoweredBySPmobile.svg"


const PoweredLogo = () => {
    const below800 = useMedia('(max-width: 800px)')
    return (
      <div style={{ padding: "15px", position: "absolute", bottom: 0, display: 'flex', width: '100%', justifyContent: 'center'}}>
        {!below800? <img src={Logo} alt="power_logo" width={"60%"}  /> : <img src={LogoMobile} alt="power_logo" width={"40%"}  /> }
      </div>
    );

}

const Sidebar = () => (
    <nav id={'sidebar'} style={{position: 'relative'}}>
        <div className="sidebar-header px-2 text-center">
            <img alt="SparkDeFi"
                 className=""
                 src={logo}
                 width={300}
                // height={72}
            />
        </div>

        <ul className="list-unstyled components ml-2">
            <li>
                <NavLink to="/stake" className='text-white'>
                    <img alt="Liquidity Staking" src={stake} width={35}/> {' '}
                    Liquidity Staking
                </NavLink>
            </li>
            <li>
                <NavLink to="/pool" className='text-white'>
                    <img alt="Pool-based Staking" src={pool} width={35}/> {' '}
                    Pool-Based Staking
                </NavLink>
            </li>
            <li>
                <NavLink to="/lend" className='text-white'>
                    <img alt="P2P Lending" src={lend} width={35}/> {' '}
                    P2P Lending
                </NavLink>
            </li>
            <li>
                <NavLink to="/manage" className='text-white'>
                    <img alt="Manage DeFi Assets" src={manage} width={35}/> {' '}
                    Manage DeFi Assets
                </NavLink>
            </li>
            <li>
                <NavLink to="/bridge" className='text-white'>
                    <img alt="Bridge" src={bridge} width={35}/>{' '}
                    Bridge
                </NavLink>
            </li>
            <li>
                <a href="https://sparkswap.finance" target="_blank" className='text-white' rel="noopener noreferrer">
                    <img alt="Swap" src={swap} width={35}/>{' '}
                    Swap
                </a>
            </li>
            <li>
                <NavLink to="/vote" className='text-white'>
                    <img alt="Vote" src={vote} width={35}/>{' '}
                    Vote
                </NavLink>
            </li>

            {/*<li className="active">*/}
            {/*    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Home</a>*/}
            {/*    <ul className="collapse list-unstyled" id="homeSubmenu">*/}
            {/*        <li>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <a href="#">Home 2</a>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <a href="#">Home 3</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <NavLink to="#">Home 1</NavLink>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <a href="#">Portfolio</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <a href="#">Contact</a>*/}
            {/*</li>*/}
        </ul>
        <div  style={{margin: '150px'}}/>
        <PoweredLogo />
    </nav>
    /*<div className="sidebar">
      <Row className="p-3">
        <Col xs={12} className="d-none d-md-block">
          <a href="https://srk.finance" target="_blank" rel="noopener noreferrer">
              <img alt="SparkDeFi" className="img" src={logo} /></a>
        </Col>
        <Col xs={12} className="d-md-none">
          <a href="https://srk.finance" target="_blank" rel="noopener noreferrer"><img alt="SparkDeFi" className="img" src={mobileLogo} /></a>
        </Col>
      </Row>
      <Row className="p-0 m-0">
        <Col xs={12} className="px-0 px-md-4">
            <div className="menu-container d-none d-md-block">
                <div className="sidebar-nav">
                    <ul>
                        <li><a href="https://srk.finance" target="_blank" rel="noopener noreferrer">About</a></li>
                        <li><NavLink to="/stake" activeClassName="active">Liquidity Staking</NavLink></li>
                        <li><NavLink to="/pool">Pool-Based Staking</NavLink></li>
                        <li><NavLink to="/lend">P2P Lending</NavLink></li>
                        <li><NavLink to="/manage">Manage DeFi Assets</NavLink></li>
                    </ul>
                </div>
            </div>
            <div className="mobile-menu d-md-none">
                <div className="mobile-menu-option">
                    <NavLink to="/stake" activeClassName="active">
                        <div className="menu-text">Stake</div>
                    </NavLink>
                </div>
                <div className="mobile-menu-option">
                    <NavLink to="/pool">
                        <div className="menu-text">Pool</div>
                    </NavLink>
                </div>
                <div className="mobile-menu-option">
                    <NavLink to="/lend">
                        <div className="menu-text">Lend</div>
                    </NavLink>
                </div>
                <div className="mobile-menu-option">
                    <NavLink to="/manage">
                        <div className="menu-text">Manage</div>
                    </NavLink>
                </div>
                <div className="mobile-menu-option">
                    <NavLink to="/swap">
                        <div className="menu-text">Swap</div>
                    </NavLink>
                </div>
            </div>
        </Col>
      </Row>
    </div>*/
);

export default Sidebar;
