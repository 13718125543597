import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <Row className="px-3 py-0 mx-0 mx-md-5 mt-5 mb-4 mb-md-5">
        <Col xs={12} className="p-0 m-0">
          <Heading heading={'Page not found'} />
        </Col>
      </Row>
    </div>
  );

}

export default NotFoundPage;
