import React from 'react';

const SFuelTokenPage = () => {
  return (
    <div className="sfuel-token-page">
      <h1>SFUEL Token</h1>
    </div>
  );
}

export default SFuelTokenPage;
