import Web3 from "web3";
import stripHexPrefix from 'strip-hex-prefix';
import {BINANCE_CHAIN_ID, BINANCE_SMART_CHAIN} from "./bsc/constants";
import detectEthereumProvider from '@metamask/detect-provider';

const Web3Instance = {
    initialized: false,
    web3: null,
    provider: "http://127.0.0.1:8545",
    networkType: null,
    networkColor: null,
    selectedAddress: null,
    ethProvider: null,

    setProvider(host = "http://127.0.0.1:8545", type = "Http") {
        switch (type) {
            case 'Websocket':
                this.provider = new Web3.providers.WebsocketProvider(host);
                break;
            case 'IPC':
                this.provider = new Web3.providers.IpcProvider(host);
                break;
            default:
                this.provider = new Web3.providers.HttpProvider(host);
        }
    },

    async init(props = {
        provider: {
            host: null,
            type: null
        }
    }) {
        if (this.web3 && this.initialized) {
            return this;
        }

        let web3;
        // Modern dapp browsers...
        if (window.ethereum) {
            web3 = new Web3(await this.getEthereum());
        } else {
            throw new Error('Your browser is not supported')
            // if (props.provider && props.provider.host) {
            //     this.setProvider(props.provider.host, props.provider.type);
            // } else {
            //     this.setProvider(
            //         "https://ropsten.infura.io/v3/7243989ebcce4379b90ed102e47b61dd", "Http");
            // }
            // web3 = new Web3(this.provider);
        }
        this.initialized = true;
        this.web3 = web3;
        this.provider = this.web3.currentProvider;
        this.selectedAddress = await this.web3.eth.currentProvider.selectedAddress;
        this.chainId = parseInt(stripHexPrefix(await this.web3.currentProvider.chainId));
        this.setNetworkType(await this.web3.eth.net.getNetworkType());

        return this;
    },

    async requestAccounts() {
        await this.init();

        return await this.web3.eth.requestAccounts();
    },

    setNetworkType(network) {
        if (this.chainId === BINANCE_CHAIN_ID) {
            this.networkType = BINANCE_SMART_CHAIN;
        } else {
            this.networkType = network;
        }
    },

    async reInit() {
        try {
            this.initialized = false;

            await this.init();

            return true;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async getRawWeb3() {
        try {
            await this.init();

            return this.web3;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async getSelectedAddress(address = null) {
        await this.init();
        return await this.web3.eth.currentProvider.selectedAddress;
    },

    async getNetworkType(address = null) {
        try {
            await this.init();

            return this.networkType;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async getChainID() {
        try {
            await this.init();

            return this.chainId;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    getNetworkColor() {
        switch (this.networkType) {
            case 'main':
                return '#038789';
            case 'ropsten':
                return '#e91550';
            case 'kovan':
                return '#680594';
            case 'rinkeby':
                return '#ebb33f';
            case BINANCE_SMART_CHAIN:
                return '#ebb33f';
            default:
                return 'gray';
        }
    },

    async getBalance(address = null, inEth = true) {
        try {
            await this.init();

            let balance = await this.web3.eth.getBalance(address ? address : this.selectedAddress);

            return inEth ? this.web3.utils.fromWei(balance) : balance;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async checkIfConnected() {
        try {
            const ethereum = await this.getEthereum();
            if (ethereum) {
                return !!(await ethereum.selectedAddress);
            }

            return false;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async initContract(contractData) {
        return await new this.web3.eth.Contract(contractData.abi, contractData.chain[this.chainId].address);
    },

    async getEthereum() {
        try {
            if (this.ethProvider) {
                return this.ethProvider;
            }

            this.ethProvider = await detectEthereumProvider();
            window.ethereum = this.ethProvider;
            // alert('test')
            // alert(await detectEthereumProvider())
            if (this.ethProvider) {
                return this.ethProvider
            }
            return null;
        } catch (e) {
            this.error = e;
            throw e;
        }
    },

    async ethExists() {
        try {
            if (this.ethProvider) {
                return true;
            }

            const ethProvider = await this.getEthereum();
            if (ethProvider) {
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    },

    getError() {
        return this.error;
    }
};

export default Web3Instance;
