import SrkUsdtStakingRewardsABI from '../../../../bsc/staking/liquidity/abis/SrkUsdtStakingRewards.json'
import {REACT_APP_UNIAPP_URL, SRK, SRKUSDTUNIV2, USDT} from '../../../../constants';
import StakingRewards from "../StakingRewards";

class SrkUsdtStakingRewards extends StakingRewards {
    initialized = false;

    constructor() {
        super(SrkUsdtStakingRewardsABI, {
            rewardsToken: SRK,
            pairToken: USDT,
            stakingToken: SRKUSDTUNIV2,
            dexUrl: REACT_APP_UNIAPP_URL
        });
    }
};

export default SrkUsdtStakingRewards;
