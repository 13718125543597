import {BINANCE_SMART_CHAIN} from "./bsc/constants";
import Web3Instance from "./Web3Instance";
import {onWrongNetwork} from "./helpers";
import {updateBscAccountBalances} from "./bsc/tokens/balances";
import {updateEthAccountBalances} from "./eth/tokens/balances";

let selectedWalletAddress = null;
let tokenBalances = {};

export const updateAccountBalances = async (blockchain, contracts, address) => {
    try {
        selectedWalletAddress = address;
        if (blockchain !== BINANCE_SMART_CHAIN) {
            tokenBalances = await updateEthAccountBalances(selectedWalletAddress, contracts);
        } else {
            tokenBalances = await updateBscAccountBalances(selectedWalletAddress, contracts);
        }
        tokenBalances.eth = parseFloat(await Web3Instance.getBalance());
    } catch (e) {
        console.log(e);
        onWrongNetwork();
    }
    return tokenBalances;
};
