import React from 'react';
import {Container} from 'react-bootstrap';
import TopMenu from "./TopMenu";
import Sidebar from "./Sidebar";

const Layout = (props) => {
    return (
        <>
            <div className={`wrapper ${!props.onRightNetwork ? 'd-none' : ''}`}>
                <Sidebar {...props}/>

                <div className={`content`}>
                    <TopMenu {...props}/>
                    {/*<StakeCountDown />*/}
                    <Container fluid className='px-5'>
                        <div className="mb-5">
                            {props.children}
                        </div>
                        {/*<PreviewWarning/>*/}
                    </Container>
                </div>
            </div>
            <div className={`wrapper ${props.onRightNetwork ? 'd-none' : ''}`}>
                <h5 className="btn btn-danger p-2 mx-auto my-5">Please make sure you're on Rinkeby or Ropsten</h5>
            </div>
        </>
    );
}

export default Layout;
