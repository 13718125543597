import SrkbBusdStakingRewardsABI from '../abis/SrkbBusdStakingRewards.json'
import {REACT_APP_SPARKSWAP_URL, SRKb, SRKBBUSD_SPARKLP, BUSD} from '../../../../constants';
import StakingRewards from "../../../../eth/staking/liquidity/StakingRewards";

class SrkbBusdStakingRewards extends StakingRewards {
    initialized = false;

    constructor(lpTokenInstance) {
        super(SrkbBusdStakingRewardsABI, {
            lpTokenInstance: lpTokenInstance,
            rewardsToken: SRKb,
            pairToken: BUSD,
            stakingToken: SRKBBUSD_SPARKLP,
            dexUrl: REACT_APP_SPARKSWAP_URL,
            pairDexAdd: '0xC3440c10c4F36f354eB591B19FafB4906d449B75_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            rewardDexAdd: '0xC3440c10c4F36f354eB591B19FafB4906d449B75',
            ethDexAdd: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
        });
    }

    async getLiquidityUrl(action = 'add') {
        if (!this.initialized) {
            await this.init()
        }

        return this.dexUrl
            + `/#/add/`
            + this.rewardsToken.address
            + '/'
            + this.pairToken;
    }

}

export default SrkbBusdStakingRewards;
