import {
    AIRx,
    AIRxBNB_SPARKLP,
    BCP,
    BCPBNB_SPARKLP,
    BSRK,
    BSRKBNB_CAKELP,
    BUSD,
    CHIMOM,
    CHIMOMBNB_SPARKLP,
    FIG,
    FIGBNB_SPARKLP,
    SFUEL,
    SFUELBNB_CAKELP,
    SRKb,
    SRKBBNB_SPARKLP,
    SRKBBUSD_SPARKLP,
    WBNB,
    KGO,
    KGOBNB_SPARKLP,
    OWN
} from "../bsc/tokens/constants"

import {SRK, SRKETHUNIV2, SRKUSDTUNIV2, USDT,} from "../eth/tokens/constants"
import {BSC_LP_PAIR_INFO} from "../bsc/staking/liquidity/pairInfo";
import {ETH_LP_PAIR_INFO} from "../eth/staking/liquidity/pairInfo";

export const LP_PAIR_INFO = {
    ... BSC_LP_PAIR_INFO,
    ... ETH_LP_PAIR_INFO
}

export {
    WBNB,
    SFUEL,
    BSRK,
    BSRKBNB_CAKELP,
    SRKb,
    CHIMOM,
    BCP,
    BCPBNB_SPARKLP,
    CHIMOMBNB_SPARKLP,
    BUSD,
    SRKBBNB_SPARKLP,
    SRKBBUSD_SPARKLP,
    AIRx,
    AIRxBNB_SPARKLP,
    FIG,
    SFUELBNB_CAKELP,
    FIGBNB_SPARKLP,

    SRK,
    USDT,
    SRKETHUNIV2,
    SRKUSDTUNIV2,
    KGO,
    KGOBNB_SPARKLP,
    OWN
}

export const POOLBASED_SETTINGS = {
    'isDepositEnabled' : true,
    'isWithdrawalEnabled': true,
    categories: [
        'SFUEL-SFUEL',
        'SRKb-SRKb',
        'SRKb-SFUEL',
        'SRKb-BCP',
        'SRKb-AIRx',
        'SRKb-FiG',
        'SRKb-KGO',
        'SRKb-OWN',
        'SRKb-KCLP',
    ]
};
export const STAKE_START = 1606665600000; // Nov 30, 2020

export const STAKE_GENESIS = 1606496400; // Nov 28
export const STAKE_END = new Date(0).setUTCSeconds(1611680400);


export const BASENAME = process.env.BASENAME || '';
export const REACT_APP_UNIAPP_URL = process.env.REACT_APP_UNIAPP_URL || 'https://app.uniswap.org';
export const REACT_APP_PANCAKEAPP_URL = process.env.REACT_APP_PANCAKEAPP_URL || 'https://pancakeswap.finance/';
export const REACT_APP_SPARKSWAP_URL = process.env.REACT_APP_SPARKSWAP_URL || 'https://sparkswap.finance';
export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
export const INFURA_ETH_MAIN_RPC = (process.env.REACT_APP_INFURA_ETH_MAIN_RPC || 'https://mainnet.infura.io/v3') + `/${INFURA_PROJECT_ID}`;
export const BSC_MAIN_RPC = process.env.REACT_APP_BSC_MAIN_RPC || 'https://bsc-dataseed1.binance.org:443';
export const BRIDGE_BALANCE_RELOAD_INTERVAL = process.env.REACT_APP_BRIDGE_BALANCE_RELOAD_INTERVAL || 60000;

export const BRIDGE_STATUS = process.env.REACT_APP_BRIDGE_STATUS || '';
export const CONFIRMATION_DIALOG_STATUS = {
    CONFIRM_TRANSACTION: "confirmTransaction",
    AWAIT_CONFIRMATION: "awaitConfirmation",
    TRANSACTION_SUBMITTED: "transactionComplete"
};
export const STAKE_ACTION_TYPE = {
    APPROVE: "APPROVE",
    DEPOSIT: "DEPOSIT",
    WITHDRAW: "WITHDRAW",
    CLAIM: "CLAIM"
};
