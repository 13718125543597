import {withRouter} from 'react-router-dom';
import PoolActionsPage from "../PoolActionsPage";

class bcpSfuelPool extends PoolActionsPage {
    constructor(props) {
        super(props);
        this.poolData = props.staking['bcpSfuelLPool' + props.match.params.version];
        this.stakeTokenBal = props.wallet.balance.bcp;
        this.rewardTokenBal = props.wallet.balance.sfuel;
        this.stakeTokenContract = props.contracts.bcpToken;
        this.stakingRewardsContract = props.contracts.bcpSfuelLaunchPoolV1;
    }
}

export default withRouter(bcpSfuelPool);
