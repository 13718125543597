import {withRouter} from 'react-router-dom';
import PoolActionsPage from "../PoolActionsPage";

class SrkbKgoPool extends PoolActionsPage {
    constructor(props) {
        super(props);
        this.poolData = props.staking['srkbKgoLPool' + props.match.params.version];
        this.stakeTokenBal = props.wallet.balance.srkb;
        this.rewardTokenBal = props.wallet.balance.kgo;
        this.stakeTokenContract = props.contracts.srkbToken;
        this.stakingRewardsContract = props.contracts.srkbKgoLaunchPoolV1;
    }
}

export default withRouter(SrkbKgoPool);
