import {
    AIRx_ABI,
    AIRx_BNB_SPARK_ABI,
    BCP_ABI,
    BCP_BNB_SPARK_ABI,
    BSRK_ABI,
    BSRK_BNB_CAKE_ABI,
    BUSD_ABI,
    CHIMOM_ABI,
    CHIMOM_BNB_SPARK_ABI,
    FIG_ABI,
    FIG_BNB_SPARK_ABI,
    SFUEL_ABI,
    SFUEL_BNB_CAKE_ABI,
    SRKB_ABI,
    SRKB_BNB_SPARK_ABI,
    SRKB_BUSD_SPARK_ABI,
    WBNB_ABI,
    KGO_ABI,
    KGO_BNB_SPARK_ABI,
    OWN_ABI,
    OWN_BNB_SPARK_ABI,
    KCLP_ABI,
    KCLP_BUSD_CAKE_ABI,
    KCLP_BUSD_SPARK_ABI
} from "./abis";
import {initToken} from "../../helpers";
import {BINANCE_CHAIN_ID} from "../constants";

export const WBNB = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, WBNB_ABI),
};

export const SFUEL = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, SFUEL_ABI),
};

export const BSRK = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, BSRK_ABI),
};

export const BSRKBNB_CAKELP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, BSRK_BNB_CAKE_ABI),
};

export const SRKb = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, SRKB_ABI),
};
export const CHIMOM = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, CHIMOM_ABI),
};
export const BCP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, BCP_ABI),
};
export const BCPBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, BCP_BNB_SPARK_ABI),
};
export const CHIMOMBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, CHIMOM_BNB_SPARK_ABI),
};

export const BUSD = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, BUSD_ABI),
};

export const SRKBBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, SRKB_BNB_SPARK_ABI),
};

export const SRKBBUSD_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, SRKB_BUSD_SPARK_ABI),
};

export const AIRx = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, AIRx_ABI),
};
export const AIRxBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, AIRx_BNB_SPARK_ABI),
};

export const FIG = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, FIG_ABI),
};

export const SFUELBNB_CAKELP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, SFUEL_BNB_CAKE_ABI),
};

export const FIGBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, FIG_BNB_SPARK_ABI),
};

export const KGO = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, KGO_ABI),
};

export const KGOBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, KGO_BNB_SPARK_ABI),
};

export const OWN = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, OWN_ABI),
};

export const OWNBNB_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, OWN_BNB_SPARK_ABI),
};

export const KCLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, KCLP_ABI),
};

export const KCLPBUSD_CAKELP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, KCLP_BUSD_CAKE_ABI),
};

export const KCLPBUSD_SPARKLP = {
    [BINANCE_CHAIN_ID]: initToken(BINANCE_CHAIN_ID, KCLP_BUSD_SPARK_ABI),
};

export const BSC_TOKEN = {
    list: {
        bnb: '0.00000',
        sfuel: '0.00000',
        usdt: '0.00000',
        bsrk: '0.00000',
        srkb: '0.00000',
        busd: '0.00000',
        chimom: '0.00000',
        bcp: '0.00000',
        airx: '0.00000',
        fig: '0.00000',
        kgo: '0.00000',
        own: '0.00000',
        kclp: '0.00000',

        sfuelBnbCakeLP: '0.00000',
        bsrkbnbCakeLP: '0.00000',
        srkbBnbSparkLP: '0.00000',
        srkbBusdSparkLP: '0.00000',
        chimomBnbSparkLP: '0.00000',
        bcpBnbSparkLP: '0.00000',
        airxBnbSparkLP: '0.00000',
        figBnbSparkLP: '0.00000',
        kgoBnbSparkLP: '0.00000',
        ownBnbSparkLP: '0.00000',
        sfuelBnbCakeLpToken: '0.00000',
        bsrkbnbCakeLPToken: '0.00000',
        srkbBnbSparkLPToken: '0.00000',
        srkbBusdSparkLPToken: '0.00000',
        chimomBnbSparkLPToken: '0.00000',
        bcpBnbSparkLPToken: '0.00000',
        airxBnbSparkLPToken: '0.00000',
        figBnbSparkLPToken: '0.00000',
        kgoBnbSparkLPToken: '0.00000',
        ownBnbSparkLPToken: '0.00000',
        kclpBusdCakeLPToken: '0.00000',
        kclpBusdSparkLPToken: '0.00000',
    },
    contracts: {
        busdToken: null,
        sfuelToken: null,
        bsrkToken: null,
        srkbToken: null,
        chimomToken: null,
        bcpToken: null,
        airxToken: null,
        figToken: null,
        kgoToken: null,
        ownToken: null,
        kclpToken: null,
    }



}
