import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class OwnBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.own;
        this.lpTokenBal = props.wallet.balance.ownBnbSparkLP;

        this.lpTokenContract = props.contracts.ownBnbSparkLPToken;
        this.stakingRewardsContract = props.contracts['ownBnbStakingRewards' + props.match.params.version];

        this.poolData = props.staking['ownBnbPool' + props.match.params.version];
    }
}

export default withRouter(OwnBnbStakeActionsPage);
