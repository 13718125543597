import Token from "./Token";
import {SRK, USDT} from "./constants";
import {SRK_ABI, SRK_ETH_UNI_ABI, SRK_USDT_UNI_ABI, USDT_ABI} from "./abis";
import UniV2Pair from "./lp/UniV2Pair";

let init = {}

export const initEthTokenContracts = async () => {
    init.srkToken = new Token(SRK_ABI);
    init.uniV2Token = new UniV2Pair(SRK_ETH_UNI_ABI);

    init.usdtToken = new Token(USDT_ABI);
    init.srkUsdtUniV2Token = new UniV2Pair(SRK_USDT_UNI_ABI, SRK, USDT);

    return {
        ... init
    }
}

