import {ChainId, Token} from '@uniswap/sdk'
import {BINANCE_CHAIN_ID, BINANCE_SMART_CHAIN} from "./bsc/constants";
import {errors} from "ethers";
import {BSC_LP_PAIR_INFO} from "./bsc/staking/liquidity/pairInfo";
import {ETH_LP_PAIR_INFO} from "./eth/staking/liquidity/pairInfo";

export const buildTxUrl = (txHash, chainId) => {
    let url;

    switch (chainId) {
        case ChainId.ROPSTEN:
            url = 'http://ropsten.etherscan.io/tx/';
            break;
        case ChainId.RINKEBY:
            url = 'http://rinkeby.etherscan.io/tx/';
            break;
        case BINANCE_CHAIN_ID:
            url = 'https://bscscan.com/tx/';
            break;
        default:
            url = 'http://etherscan.io/tx/';
    }

    return url + txHash;
};

export const countDecimals = (value) => {
    if (!value) {
        return 0;
    }
    let text = value.toString();
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        // eslint-disable-next-line no-unused-vars
        let [base, trail] = text.split('e-');
        return parseInt(trail, 10);
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value && value > 0) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
};

export const showFullBalance = (value) => {
    return (countDecimals(value) >= 4)
};

export const epochToDate = (unixTimestamp) => {
    return new Date(unixTimestamp * 1000);
};

export const dateToEpoch = (date) => {
    return parseFloat(date.getTime() / 1000);
};

export const onWrongNetwork = () => {
    console.error('You are in the wrong network');
};

export const toFixed = (num, decPlaces = 17) => {
    num = num.toString();
    return num.match(/^-?\d+(?:\.\d{0,17})?/)[0]
}

export const expandValue = (web3, value, decimals) => {
    if (decimals === 0) {
        throw errors
    }
    value = web3.utils.toWei(value.toString());
    // mwei adds 6 zeros, plus additional 'x...' to make it x decimals
    return value.slice(0, value.length - (18-decimals));
}

export const shrinkValue = (web3, value, decimals) => {
    return value / Math.pow(10, decimals);
}

export const initToken = (chainID, contractData) => {
    return new Token(chainID, contractData.chain[chainID].address, contractData.decimals, contractData.symbol, contractData.contractName)
}

export const setCardDetails = (
    data = {
        totalDeposits: 0.00000,
        poolRate: 0.00000,
        rewardRate: 0.00000,
        hasEnded: false
    },
    onRightNetwork = false,
    rewardsToken = 'SRK',
    stakingToken = 'UNI-V2',
    category = 'SRK-ETH',
    versions = [],
    upcoming = false
) => {
    return {
        liquidityDepositsTotal: `${data.totalDeposits ?? 0.00000} ${stakingToken}`,
        poolRate: `${data.poolRate ?? 0.00000} ${rewardsToken} / week`,
        rewardRate: `⚡ ${data.rewardRate ?? 0.00000} ${rewardsToken} / week`,
        hasEnded: data.hasEnded,
        remainingDays: data.remainingDays,
        onRightNetwork: onRightNetwork,
        category: category,
        versions: versions,
        contract: data.contract,
        upcoming: upcoming
    }
};

export const setLPStakingData = async (
    selectedWalletAddress,
    stakingContract,
    token0,
    token1,
    approvedLPTokens = 0,
) => {
    const remainingDays = stakingContract.getRemainingDays();
    const hasStarted = stakingContract.hasStarted();
    const hasEnded = stakingContract.hasEnded();
    let stakedLPTokens = await stakingContract.getStakedTokens(selectedWalletAddress, true);
    let earnedTokens = await stakingContract.getEarnedTokens(selectedWalletAddress, true);
    let hypoRewardRate = hasEnded ? 0 : await stakingContract.getHypotheticalRewardRate(
        selectedWalletAddress,
        token0,
        token1
    );
    let poolRate = hasEnded ? 0 : await stakingContract.getTotalRewardRate(true);
    let totalDeposits = await stakingContract.getTotalSupply(true);
    return {
        approvedLPTokens: approvedLPTokens,
        stakedLPTokens: parseFloat(stakedLPTokens.toSignificant(15)),
        earnedTokens: parseFloat(earnedTokens.toSignificant(15)),
        rewardRate: parseFloat(hypoRewardRate),
        poolRate: parseFloat(poolRate.toFixed(2)),
        totalDeposits: parseFloat(totalDeposits.toSignificant(15)),
        periodFinish: stakingContract.getPeriodFinish(),
        hasStarted: hasStarted,
        hasEnded: hasEnded,
        remainingDays: remainingDays,
        contract: stakingContract
    }
};

export const setPoolStakingData = async (
    selectedWalletAddress,
    poolContract,
    stakeTokenContract,
) => {
    let remainingRewardBlocks = await poolContract.getRemainingRewardBlocks()
    return {
        stakedTokens: await poolContract.getStakedTokens(selectedWalletAddress),
        earnedTokens: await poolContract.getEarnedTokens(selectedWalletAddress),
        rewardRate: await poolContract.getRewardPerBlock(),
        totalDeposits: await poolContract.getTotalDeposits(),
        approvedTokens: parseFloat((await stakeTokenContract.allowance(
            selectedWalletAddress,
            await poolContract.getAddress(),
            true
        )).toSignificant(15)),
        remainingRewardBlocks: remainingRewardBlocks,
        remainingDays: await poolContract.getRemainingDays(),
        contract: poolContract,
        maxStake: await poolContract.getMaxStake(),
        hasEnded: await poolContract.hasEnded()
    }
}

window.toFixed = toFixed;
