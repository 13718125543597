import WBNBABI from './WBNB.json'
import AIRxABI from './AirCoin.json'
import BCPABI from './BitcashPay.json'
import BSRKABI from './bSRK.json'
import BUSDABI from './BUSD.json'
import CHIMOMABI from './Chimom.json'
import SFUELABI from './Sfuel.json'
import SRKBABI from './SRKb.json'
import FIGABI from './FlowCom.json'
import KGOABI from './Kiwigo.json'
import OWNABI from './Ownly.json'
import KCLPABI from './KCLP.json'

import AIRxBNBSPARKABI from './lp/AirxBnbSparkPair.json'
import BCPBNBSPARKABI from './lp/BcpBnbSparkPair.json'
import BSRKBNBCAKEABI from './lp/BsrkBnbPancakePair.json'
import CHIMOMBNBSPARKABI from './lp/ChimomBnbSparkPair.json'
import SFUELBNBCAKEABI from './lp/SfuelBnbPancakePair.json'
import SRKBBNBSPARKABI from './lp/SrkbBnbSparkPair.json'
import SRKBBUSDSPARKABI from './lp/SrkbBusdSparkPair.json'
import FIGBNBSPARKABI from './lp/FigBnbSparkPair.json'
import KGOBNBSPARKABI from './lp/KgoBnbSparkPair.json'
import OWNBNBSPARKABI from './lp/OwnBnbSparkPair.json'
import KCLPBUSDCAKEABI from './lp/KclpBusdCakePair.json'
import KCLPBUSDSPARKABI from './lp/KclpBusdSparkPair.json'

export const WBNB_ABI  = WBNBABI;
export const AIRx_ABI  = AIRxABI;
export const BCP_ABI  = BCPABI;
export const BSRK_ABI  = BSRKABI;
export const BUSD_ABI  = BUSDABI;
export const CHIMOM_ABI  = CHIMOMABI;
export const SFUEL_ABI  = SFUELABI;
export const SRKB_ABI  = SRKBABI;
export const FIG_ABI  = FIGABI;
export const KGO_ABI  = KGOABI;
export const OWN_ABI  = OWNABI;
export const KCLP_ABI  = KCLPABI;
export const AIRx_BNB_SPARK_ABI  = AIRxBNBSPARKABI;
export const BCP_BNB_SPARK_ABI  = BCPBNBSPARKABI;
export const BSRK_BNB_CAKE_ABI  = BSRKBNBCAKEABI;
export const CHIMOM_BNB_SPARK_ABI  = CHIMOMBNBSPARKABI;
export const SFUEL_BNB_CAKE_ABI  = SFUELBNBCAKEABI;
export const SRKB_BNB_SPARK_ABI  = SRKBBNBSPARKABI;
export const SRKB_BUSD_SPARK_ABI  = SRKBBUSDSPARKABI;
export const FIG_BNB_SPARK_ABI  = FIGBNBSPARKABI;
export const KGO_BNB_SPARK_ABI  = KGOBNBSPARKABI;
export const OWN_BNB_SPARK_ABI  = OWNBNBSPARKABI;
export const KCLP_BUSD_CAKE_ABI  = KCLPBUSDCAKEABI;
export const KCLP_BUSD_SPARK_ABI  = KCLPBUSDSPARKABI;
