import {setLPStakingData} from "../../../helpers";

export const BSC_LP_STAKING = {
    list: {
        sfuelBnbPool: {},
        bsrkBnbPool: {},
        srkbBnbPoolV1: {},
        srkbBnbPoolV2: {},
        srkbBusdPool: {},
        chimomBnbPoolV1: {},
        bcpBnbPoolV1: {},
        airxBnbPoolV1: {},
        figBnbPoolV1: {},
        kgoBnbPoolV1: {},
        ownBnbPoolV1: {},
        kclpBusdPoolV1: {},
        kclpBusdSfuelPoolV1: {},
    },
    contracts: {
        // SFUEL-BNB POOL
        sfuelBnbStakingRewards: null,
        // BSRK-BNB POOL
        bsrkBnbStakingRewards: null,
        // SRKB-BNB POOL
        srkbBnbStakingRewardsV1: null,
        srkbBnbStakingRewardsV2: null,
        // SRKB-BUSD POOL
        srkbBusdStakingRewards: null,
        // CHIMOM-BNB POOL
        chimomBnbStakingRewardsV1: null,
        // BCP-BNB POOL
        bcpBnbStakingRewardsV1: null,
        // BCP-BNB POOL
        airxBnbStakingRewardsV1: null,
        // FIG-BNB POOL
        figBnbStakingRewardsV1: null,
        // KGP-BNB POOL
        kgoBnbStakingRewardsV1: null,
        // OWN-BNB POOL
        ownBnbStakingRewardsV1: null,
        kclpBusdStakingRewardsV1: null,
        kclpBusdSfuelStakingRewardsV1: null,

    }
}

export const updateBscLiquidityStakingData = async (
    selectedWalletAddress,
    contracts
) => {

    let stakingDetails = {};

    let approvedLPTokens = parseFloat((await contracts.sfuelBnbCakeLpToken.allowance(
        selectedWalletAddress,
        await contracts.sfuelBnbStakingRewards.getAddress(),
        true
    )).toSignificant(15));
    stakingDetails.sfuelBnbPool = await setLPStakingData(
        selectedWalletAddress,
        contracts.sfuelBnbStakingRewards,
        await contracts.sfuelBnbCakeLpToken.token0(false),
        await contracts.sfuelBnbCakeLpToken.token1(false),
        approvedLPTokens
    );

    approvedLPTokens = parseFloat((await contracts.bsrkbnbCakeLPToken.allowance(
        selectedWalletAddress,
        await contracts.bsrkBnbStakingRewards.getAddress(),
        true
    )).toSignificant(15));

    stakingDetails.bsrkBnbPool = await setLPStakingData(
        selectedWalletAddress,
        contracts.bsrkBnbStakingRewards,
        await contracts.bsrkbnbCakeLPToken.token0(false),
        await contracts.bsrkbnbCakeLPToken.token1(false),
        approvedLPTokens
    );

    approvedLPTokens = parseFloat((await contracts.srkbBnbSparkLPToken.allowance(
        selectedWalletAddress,
        await contracts.srkbBnbStakingRewardsV1.getAddress(),
        true
    )).toSignificant(15));

    stakingDetails.srkbBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkbBnbStakingRewardsV1,
        await contracts.srkbBnbSparkLPToken.token0(false),
        await contracts.srkbBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.srkbBnbPoolV2 = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkbBnbStakingRewardsV2,
        await contracts.srkbBnbSparkLPToken.token0(false),
        await contracts.srkbBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    approvedLPTokens = parseFloat((await contracts.srkbBusdSparkLPToken.allowance(
        selectedWalletAddress,
        await contracts.srkbBusdStakingRewards.getAddress(),
        true
    )).toSignificant(15));

    stakingDetails.srkbBusdPool = await setLPStakingData(
        selectedWalletAddress,
        contracts.srkbBusdStakingRewards,
        await contracts.srkbBusdSparkLPToken.token0(false),
        await contracts.srkbBusdSparkLPToken.token1(false),
        approvedLPTokens,
    );

    approvedLPTokens = parseFloat((await contracts.chimomBnbSparkLPToken.allowance(
        selectedWalletAddress,
        await contracts.chimomBnbStakingRewardsV1.getAddress(),
        true
    )).toSignificant(15));

    stakingDetails.chimomBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.chimomBnbStakingRewardsV1,
        await contracts.chimomBnbSparkLPToken.token0(false),
        await contracts.chimomBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    // BCP-BNB
    approvedLPTokens = parseFloat((await contracts.bcpBnbSparkLPToken.allowance(
        selectedWalletAddress,
        await contracts.bcpBnbStakingRewardsV1.getAddress(),
        true
    )).toSignificant(15));

    stakingDetails.bcpBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.bcpBnbStakingRewardsV1,
        await contracts.bcpBnbSparkLPToken.token0(false),
        await contracts.bcpBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.airxBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.airxBnbStakingRewardsV1,
        await contracts.airxBnbSparkLPToken.token0(false),
        await contracts.airxBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.figBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.figBnbStakingRewardsV1,
        await contracts.figBnbSparkLPToken.token0(false),
        await contracts.figBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.kgoBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.kgoBnbStakingRewardsV1,
        await contracts.kgoBnbSparkLPToken.token0(false),
        await contracts.kgoBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.ownBnbPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.ownBnbStakingRewardsV1,
        await contracts.ownBnbSparkLPToken.token0(false),
        await contracts.ownBnbSparkLPToken.token1(false),
        approvedLPTokens,
    );

    stakingDetails.kclpBusdPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.kclpBusdStakingRewardsV1,
        await contracts.kclpBusdCakeLPToken.token0(false),
        await contracts.kclpBusdCakeLPToken.token1(false),
        approvedLPTokens,
    );
    stakingDetails.kclpBusdSfuelPoolV1 = await setLPStakingData(
        selectedWalletAddress,
        contracts.kclpBusdSfuelStakingRewardsV1,
        await contracts.kclpBusdSparkLPToken.token0(false),
        await contracts.kclpBusdSparkLPToken.token1(false),
        approvedLPTokens,
    );

    return stakingDetails;
};
