import React, {useEffect, useRef, useState} from 'react';
import {Button, Nav, Navbar} from "react-bootstrap";
import mobileLogo from "../../assets/images/sparkdefi-logo.png";
import {Dialog} from "@material-ui/core";
import Web3Instance from "../../blockchain/Web3Instance";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWallet, faBars} from "@fortawesome/free-solid-svg-icons";


const TopMenu = ({wallet, connectToWallet}) => {
    const [isOpenDialogOpen, toggleConnectDialog] = useState(false);
    const [isProviderDetected, updateIsProviderDetected] = useState(false);
    const [isConnecting, updateIsConnecting] = useState(false);

    useEffect(() => {
        let mounted = true;
        Web3Instance.ethExists().then(async (exists) => {
            if (exists && mounted) {
                updateIsProviderDetected(true);
            }
        });

        Web3Instance.checkIfConnected().then(hasSelectedAddress => {
            if (mounted) {
                updateIsConnecting(hasSelectedAddress && !wallet.connected);
            }
        })

        return function cleanup() {
            mounted = false;   // clean up function
        };
    },[Web3Instance]);
    const toggleConnectWalletDialog = () => (
        <Dialog open={isOpenDialogOpen}>
            <div>
                <Button type="button"
                        className="close px-1"
                        aria-label="Close"
                        onClick={() => {
                            toggleConnectDialog(false)
                        }}
                >
                    <span aria-hidden="true">&times;</span>
                </Button>
            </div>
            <div className={'px-5 pb-5 pt-4'}>
                {
                    isProviderDetected ?
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={async () => {
                                await connectToWallet();
                                window.location.reload()
                                toggleConnectDialog(false);
                            }}>
                            Connect with MetaMask <span role="img" aria-labelledby="MetaMask logo">🦊</span>
                        </Button> :
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                window.open('http://metamask.io/')
                            }}>
                            Install MetaMask <span role="img" aria-labelledby="MetaMask logo">🦊</span>
                        </Button>
                }
            </div>
        </Dialog>
    );

    let mainBal = 0;
    if (wallet.balance.eth && wallet.balance.eth.toFixed) {
        mainBal = wallet.balance.eth.toFixed(6)
    }

    return (
        <Navbar expand="lg" id={'topBar'}>
            <button aria-controls="sidebar"
                    type="button"
                    aria-label="Toggle navigation"
                    className="navbar-toggler collapsed border"
                    onClick={() => {
                        document.getElementById('sidebar').classList.toggle('active')
                    }}
            >
                <FontAwesomeIcon icon={faBars} size={'1x'} className={'text-white'}/>
            </button>
            <Navbar.Brand href="https://srk.finance" target="_blank" rel="noopener noreferrer">
                <span className="d-lg-none">
                    <img alt="SparkDeFi"
                         className=""
                         src={mobileLogo}
                         width={50}
                         height={71}
                    />
                </span>
            </Navbar.Brand>
            <button aria-controls="info-bar"
                    type="button"
                    aria-label="Toggle navigation"
                    className="navbar-toggler collapsed border"
                    onClick={() => {
                        document.getElementById('info-bar').classList.toggle('show')
                    }}
            >
                <FontAwesomeIcon icon={faWallet} size={'1x'} className={'text-white'}/>
            </button>
            <Navbar.Collapse id="info-bar">
                <Nav className="ml-auto">
                    {/*{*/}
                    {/*    wallet.connected ?*/}
                    {/*}*/}
                    <Nav.Link>
                        <span className="rounded p-1 text-white"
                              style={{'background': wallet.networkColor}}>{wallet.network}</span>
                    </Nav.Link>
                    <Nav.Link className={'text-white'}
                              onClick={() => {
                                  if (!wallet.connected) {
                                      toggleConnectDialog(true);
                                  }
                              }}>
                        {isConnecting?
                            <>Connecting <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></>
                            :
                            ''}
                        {!wallet.connected && !isConnecting ? 'Connect Wallet' :
                            <span>
                                <strong>{wallet.maskedAddress}</strong> {' '}
                                <small
                                    className='text-primary'><strong>({mainBal} {wallet.ethSymbol})</strong></small>
                            </span>
                        }
                    </Nav.Link>
                    {/* When Pool based is available
                <NavDropdown title="Staking" id="basic-nav-dropdown">
                    <NavLink className='dropdown-item' to="/stake">Liquidity</NavLink>
                    <NavLink className='dropdown-item' to="/stake">Pool-Based</NavLink>
                </NavDropdown>*/}
                </Nav>
            </Navbar.Collapse>

            {toggleConnectWalletDialog()}
        </Navbar>
    );
};

export default TopMenu;
