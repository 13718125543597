import React, {useEffect, useState} from 'react';
import {Alert, Col, Row, Tab, Tabs} from 'react-bootstrap';
import styled from 'styled-components';
import Heading from '../../components/Heading';
import StakeBox from './components/StakeBox';
import {getEthLPStakingCardDetails} from "../../blockchain/eth/staking/liquidity/cardDetails";
import {getBscLPStakingCardDetails} from "../../blockchain/bsc/staking/liquidity/cardDetails";
import Web3Instance from "../../blockchain/Web3Instance";
import {BINANCE_SMART_CHAIN} from "../../blockchain/bsc/constants";
import {BSC_LP_PAIR_INFO} from "../../blockchain/bsc/staking/liquidity/pairInfo";
import {ETH_LP_PAIR_INFO} from "../../blockchain/eth/staking/liquidity/pairInfo";

const LiquidityStakingPage = ({blockchain, staking, wallet}) => {
    document.title = "Liquidity Staking | SparkDeFi Web App";

    const stakingList = {
        ethereum: getEthLPStakingCardDetails(blockchain, staking),
        SmartChain: getBscLPStakingCardDetails(blockchain, staking)
    }
    const [isConnecting, updateIsConnecting] = useState(false);

    useEffect(() => {
        Web3Instance.checkIfConnected().then(hasSelectedAddress => updateIsConnecting(hasSelectedAddress && !wallet.connected))
    }, [])

    const activeStaking = Object.keys(stakingList[blockchain])
                            .filter(key => !stakingList[blockchain][key].hasEnded && !stakingList[blockchain][key].upcoming).reverse()
    const upcomingStaking = Object.keys(stakingList[blockchain])
                            .filter(key => stakingList[blockchain][key].upcoming).reverse()
    const endedStaking = Object.keys(stakingList[blockchain])
                            .filter(key => stakingList[blockchain][key].hasEnded).reverse()

    const newUiText = "The refreshed UI for Staking is now LIVE! Check it here" ;
    const headingText = "Liquidity Staking";
    const subheadingText = "Earn SRK, SFUEL and other Tokens by participating in our Liquidity Pools.";


    const getPairInfo = (blockchain, category) => {
        if (blockchain === BINANCE_SMART_CHAIN) {
            return BSC_LP_PAIR_INFO[category];
        }

        return ETH_LP_PAIR_INFO[category]
    }

    const tabData = (details, category = 'active') => {
        if (isConnecting) {
                return <>Hang on, loading staking pools <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div></>
        }
        if (!details.length) {
            return <Alert severity="info" className="mb-2">No {category} pools ...</Alert>
        }
        return (
            <div className="container-fluid">
                <Row className="p-0 py-0 my-2 d-flex justify-content-center mx-n4">

                    {details
                        .map((key, index) => {
                            return <Col xs={12} md={6} lg={6} className="pt-1 mb-2" key={key} style={{'minWidth': '380px' }}>
                                <StakeBox type={getPairInfo(blockchain, stakingList[blockchain][key]['category'] + '-V1')}
                                          data={stakingList[blockchain][key]}
                                          wallet={wallet}
                                />
                            </Col>
                        })
                    }
                </Row>
            </div>
        );
    }
    return (
        <div className="liquidity-staking-page">
            <Row className="py-0 mt-5 mb-3 mb-md-3">
                <Col xs={12} lg={12} className="">
                    <div 
                        style={{ fontSize: '18px', color: '#ffffff', textAlign: 'center', backgroundColor: '#007bff ', marginBottom: '4.5vh', padding: '3vh', borderRadius: '6px' }}> 
                        <strong>
                            {newUiText} <a href="https://stake.sparkswap.finance/" style={{ color:'#ffffff' }}> https://stake.sparkswap.finance</a>
                        </strong>
                    </div>
                    <Heading
                        heading={headingText}
                        headingColor={'#3cbbe8'}
                        subheading={subheadingText}
                        subheadingColor={'#fff'}
                    />
                </Col>
            </Row>

            <Tabs defaultActiveKey="active" id="staking-pools" className="mb-4">
                <Tab eventKey="active" title={<span>Active <span className="badge badge-primary">{activeStaking.length}</span></span>}>
                    {tabData(activeStaking, 'active')}
                </Tab>
                <Tab eventKey="upcoming" title={<span>Upcoming <span className="badge badge-primary">{upcomingStaking.length}</span></span>}>
                    {tabData(upcomingStaking, 'upcoming')}
                </Tab>
                <Tab eventKey="ended" title={<span>Ended <span className="badge badge-primary">{endedStaking.length}</span></span>}>
                    {tabData(endedStaking, 'inactive')}
                </Tab>
            </Tabs>
        </div>
    );
};

export default LiquidityStakingPage;
