import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Button, ButtonGroup, ButtonToolbar, Col, Collapse, Container, Form, InputGroup, Row} from 'react-bootstrap';
import Box from '../../components/Box';
import CustomButton from '../../components/Button';
import Heading from '../../components/Heading';
import ModalDialog from '../../components/ModalDialog';
import {getTokenIcon} from "../../blockchain/constants/tokenIcons";
import numeral from 'numeral';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faWaveSquare} from "@fortawesome/free-solid-svg-icons";
import {showFullBalance} from '../../blockchain/helpers'
import {STAKING_PAIR_INFO} from "../../blockchain/bsc/staking/pool/pairInfo";
import {CONFIRMATION_DIALOG_STATUS, POOLBASED_SETTINGS, STAKE_ACTION_TYPE} from "../../blockchain/constants";
import {Notice} from "../../components/Notice";

class PoolActionsPage extends Component {
    constructor(props) {
        super(props);
        this.category = props.match.params.category;
        this.stakingData = STAKING_PAIR_INFO[props.match.params.category + '-' + props.match.params.version];
        this.selectedAddress = props.wallet.address;
        this.isConnected = props.wallet.connected;
        this.chainId = props.wallet.chain_id;
        this.updateAccountInfo = props.updateAccountInfo.bind(this);
        this.handleMax = this.handleMax.bind(this);
        this.toggleStakeForm = this.toggleStakeForm.bind(this)
        this.state = {
            approvedTokens: 0.00,
            depositAmount: 0.00,
            stakeable: 0.00,

            canStake: false,
            canDeposit: false,
            isApproveLoading: false,
            isDepositLoading: false,
            isWithdrawLoading: false,
            isClaimLoading: false,
            isTransactionComplete: false,
            isInputAmountDisabled: true,
            isApproveDisabled: true,
            isClaimDisabled: true,
            isWithdrawDisabled: true,
            confirmModalShow: false,
            isStakeFormOpen: false,
            isWithDrawFormOpen: false,

            approveButtonText: 'Approve',
            depositButtonText: 'Deposit',
            withdrawButtonText: 'Withdraw',
            claimButtonText: 'Claim',

            txUrl: '',

            modalHeaderTitle: '',
            modalContentType: STAKE_ACTION_TYPE.APPROVE,
            confirmationDialogStatus: CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION,

            signatureData: {
                isSet: false,
                v: '',
                r: '',
                s: '',
                deadline: ''
            }
        };
    }

    isWithdrawDisabled() {
        return !POOLBASED_SETTINGS.isWithdrawalEnabled && POOLBASED_SETTINGS.categories.includes(this.category)
    }

    isDepositDisabled() {
        return !POOLBASED_SETTINGS.isDepositEnabled && POOLBASED_SETTINGS.categories.includes(this.category)
    }

    initializeStakingData() {
        this.stakedTokens = parseFloat(this.poolData.stakedTokens);
        this.earnedTokens = parseFloat(this.poolData.earnedTokens);
        this.setState({
            approvedTokens: parseFloat(this.poolData.approvedTokens),
            stakeable: this.poolData.maxStake - this.stakedTokens
        })
        this.totalDeposits = this.poolData.totalDeposits;
        this.APR = this.poolData.APR;
        this.rewardRate = this.poolData.rewardRate;
        this.hasEnded = this.poolData.hasEnded;
        this.maxStake = this.poolData.maxStake;
    }

    componentDidMount() {
        this.initializeStakingData()

        if (this.stakedTokens > 0) {
            this.enableWithdrawal();
        }

        if (this.stakeTokenBal > 0 && !this.hasEnded) {
            this.enableStaking();
        }
    }

    enableStaking() {
        this.setState({
            'isInputAmountDisabled': false,
            'canStake': true
        })
    }

    enableWithdrawal() {
        this.setState({
            'isWithdrawDisabled': false,
            'isClaimDisabled': false
        })
    }

    async handleConfirmApproveToken() {
        this.setState({
            'isApproveLoading': true,
            'modalHeaderTitle': '',
        });
        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            await this.stakeTokenContract.approve(
                this.selectedAddress,
                await this.stakingRewardsContract.getAddress(),
                this.state.depositAmount
            );

            this.setState({
                'txUrl': '',
                'approvedTokens': parseFloat(this.state.depositAmount),
                'canDeposit': true,
                'isApproveDisabled': true,
                'confirmationDialogStatus': CONFIRMATION_DIALOG_STATUS.TRANSACTION_SUBMITTED,
                'isTransactionComplete': true,
                'approveButtonText': 'Approved',
                'isApproveLoading': false,
            });
        } catch (e) {
            this.setState({
                'isApproveLoading': false,
                'modalHeaderTitle': '',
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
            console.error(e);
        }
    }

    async handleConfirmDepositToken() {
        this.setState({
            'isDepositLoading': true,
            'modalHeaderTitle': ''
        });
        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            const txData = await this.stakingRewardsContract.stake(
                this.selectedAddress,
                this.state.depositAmount,
            );

            this.setState({
                'txUrl': txData.txUrl,
                'approvedTokens': this.state.approvedTokens - this.state.depositAmount,
                'depositButtonText': 'Deposited',
                'isDepositLoading': false,
                'canDeposit': false
            });
            this.transactionSubmitted();
            await this.updateAccountInfo(true);
        } catch (e) {
            this.setState({
                'depositButtonText': 'Deposit',
                'isDepositLoading': false,
                'canDeposit': false
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
            console.error(e);
        }
    }

    async handleConfirmWithdrawToken() {
        this.setState({
            'isWithdrawLoading': true,
            'modalHeaderTitle': ''
        });

        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            const txData = await this.stakingRewardsContract.withdraw(this.selectedAddress, this.stakedTokens);
            this.setState({
                'updateTxUrl': txData.txUrl,
                'withdrawButtonText': 'Withdrawn',
                'isWithdrawLoading': false,
            });

            this.transactionSubmitted();
            await this.updateAccountInfo(true);
        } catch (e) {
            console.error(e);
            this.setState({
                'withdrawButtonText': 'Withdraw',
                'isWithdrawLoading': false,
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
        }
    };

    async handleConfirmClaim() {
        this.setState({
            'isClaimLoading': true,
            'modalHeaderTitle': ''
        });

        this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION);

        try {
            const txData = await this.stakingRewardsContract.claim(this.selectedAddress, 0);
            this.setState({
                'updateTxUrl': txData.txUrl,
                'claimButtonText': 'Claimed',
                'isClaimLoading': false,
            });

            this.transactionSubmitted();
            await this.updateAccountInfo(true);
        } catch (e) {
            console.error(e);
            this.setState({
                'claimButtonText': 'Withdraw',
                'isClaimLoading': false,
            });
            this.handleHideModalDialog();
            this.setConfirmationStatus(CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION);
        }
    };


    handleInputFocus(e) {
        this.setState({'depositAmount': ''});

        if (e.target.name === "depositAmount") {
            this.checkApproveStakeStatus("");
        }
    };


    handleInputChange(e) {
        this.setState({'depositAmount': e.target.value + ''});
        if (e.target.name === "depositAmount") {
            this.checkApproveStakeStatus(e.target.value);
        }
    };

    handleInputBlur(e) {
        if (e.target.name === "depositAmount") {
            this.checkApproveStakeStatus(e.target.value);
        }
    };

    checkApproveStakeStatus(value) {
        value = parseFloat(value || '0');
        if ((value > 0 &&
            value <= this.state.approvedTokens &&
            value <= this.stakeTokenBal &&
            this.isWithinLimits(value))
        ) {
            this.setState({
                'canDeposit': true,
                'isApproveDisabled': true,
                'approveButtonText': 'Approved',
                'depositButtonText': 'Deposit'
            });
        } else {
            if (value > 0 && value > this.state.approvedTokens
                && value <= this.stakeTokenBal
                && this.isWithinLimits(value)) {
                this.setState({
                    'canDeposit': false,
                    'isApproveDisabled': false,
                    'approveButtonText': 'Approve',
                    'depositButtonText': 'Deposit'
                });
            } else {
                this.setState({
                    'canDeposit': false,
                    'isApproveDisabled': true,
                    'approveButtonText': 'Approve',
                    'depositButtonText': 'Deposit'
                });
            }
        }
    }

    handleOpenConfirmModal(action) {
        let modalHeaderTitle = "";

        switch (action) {
            case STAKE_ACTION_TYPE.APPROVE:
                modalHeaderTitle = <span
                    className="action-confirmation-text">You will {STAKE_ACTION_TYPE.APPROVE.toLowerCase()}</span>;
                break;
            case STAKE_ACTION_TYPE.DEPOSIT:
                modalHeaderTitle = <span
                    className="action-confirmation-text">You will {STAKE_ACTION_TYPE.DEPOSIT.toLowerCase()}</span>;
                break;
            case STAKE_ACTION_TYPE.WITHDRAW:
                modalHeaderTitle = <span
                    className="action-confirmation-text">You will {STAKE_ACTION_TYPE.WITHDRAW.toLowerCase()}</span>;
                break;
            case STAKE_ACTION_TYPE.CLAIM:
                modalHeaderTitle =
                    <span className="action-confirmation-text">You will {STAKE_ACTION_TYPE.CLAIM.toLowerCase()}</span>;
                break;
            default:
        }

        this.setState({
            'modalHeaderTitle': modalHeaderTitle,
            'modalContentType': action,
            'isTransactionComplete': false,
            'confirmModalShow': true,
        });
    };

    handleHideModalDialog() {
        this.setState({
            'confirmModalShow': false,
            'confirmationDialogStatus': CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION
        })
    };

    setConfirmationStatus(status) {
        this.setState({'confirmationDialogStatus': status});
    };

    transactionSubmitted() {
        this.setState({
            'confirmationDialogStatus': CONFIRMATION_DIALOG_STATUS.TRANSACTION_SUBMITTED,
            'isTransactionComplete': true
        });
    };

    async openSwapUrl(urlType) {
        let url = '';
        try {
            url = urlType === 'swap' ? await this.stakingRewardsContract.getSwapUrl() :
                await this.stakingRewardsContract.getRewardUrl();
            url = url.replace('undefined', 'https://app.uniswap.org');
            window.open(url, '_blank');
        } catch (e) {
            console.error(e);
        }
    };

    toggleStakeForm() {
        this.setState({isStakeFormOpen: !this.state.isStakeFormOpen})
    }

    updateDepositAmount(amount) {
        this.setState({
            depositAmount: amount
        });
        this.checkApproveStakeStatus(amount);
    }

    toggleWithDrawForm() {
        this.setState({
            isWithDrawFormOpen: !this.state.isWithDrawFormOpen
        })
    }

    getNetworkInfoMessage() {
        return <>
                <span className={'badge badge-danger py-1'}>
                    <FontAwesomeIcon icon={faWaveSquare} size={'1x'}
                                     className={'text-white'}/>&nbsp; Wrong Network</span>
            <small className={'d-block pt-1 text-muted'}>
                Please switch to an {this.stakingData.BLOCKCHAIN} Network
            </small>
        </>;
    }

    renderModalDialog() {
        let modalContent;
        let isWaiting;
        let buttonTitle;
        let confirmAction;

        switch (this.state.modalContentType) {
            case STAKE_ACTION_TYPE.APPROVE:
                buttonTitle = "Approve";
                confirmAction = this.handleConfirmApproveToken.bind(this);
                break;
            case STAKE_ACTION_TYPE.DEPOSIT:
                buttonTitle = "Deposit";
                confirmAction = this.handleConfirmDepositToken.bind(this);
                break;
            case STAKE_ACTION_TYPE.WITHDRAW:
                buttonTitle = "Withdraw";
                confirmAction = this.handleConfirmWithdrawToken.bind(this);
                break;
            case STAKE_ACTION_TYPE.CLAIM:
                buttonTitle = "Claim";
                confirmAction = this.handleConfirmClaim.bind(this);
                break;
            default:
                buttonTitle = "Approve";
                confirmAction = this.handleConfirmApproveToken.bind(this);
        }

        switch (this.state.confirmationDialogStatus) {
            case CONFIRMATION_DIALOG_STATUS.CONFIRM_TRANSACTION:
                modalContent = this.renderConfirmTransaction(this.state.modalContentType);
                isWaiting = false;
                break;
            case CONFIRMATION_DIALOG_STATUS.AWAIT_CONFIRMATION:
                modalContent = this.renderAwaitConfirmation();
                isWaiting = true;
                break;
            case CONFIRMATION_DIALOG_STATUS.TRANSACTION_SUBMITTED:
                modalContent = this.renderTransactionComplete(this.state.modalContentType);
                isWaiting = false;
                break;
            default:
                modalContent = this.renderConfirmTransaction(this.state.modalContentType);
                isWaiting = false;
        }

        return (
            <ModalDialog
                show={this.state.confirmModalShow}
                onHide={this.handleHideModalDialog.bind(this)}
                readOnly
                buttontitle={buttonTitle}
                isWaiting={isWaiting}
                iscomplete={this.state.isTransactionComplete}
                confirmation={confirmAction}
                headertitle={this.state.modalHeaderTitle}
                size='lg'
            >
                {modalContent}
            </ModalDialog>
        )
    };

    renderConfirmTransaction(action) {
        let amount = "", amount2 = '', tokenType = '', tokenType2 = '';
        switch (action) {
            case STAKE_ACTION_TYPE.APPROVE:
                amount = this.state.depositAmount;
                tokenType = this.stakingData.STAKE_TYPE + ' Tokens';
                break;
            case STAKE_ACTION_TYPE.DEPOSIT:
                amount = this.state.depositAmount;
                tokenType = this.stakingData.STAKE_TYPE + ' Tokens';
                break;
            case STAKE_ACTION_TYPE.WITHDRAW:
                amount = this.stakedTokens;
                tokenType = this.stakingData.STAKE_TYPE + ` Tokens`;
                amount2 = this.earnedTokens;
                tokenType2 = `${this.stakingData.TOKEN2} Tokens`;
                break;
            case STAKE_ACTION_TYPE.CLAIM:
                amount = this.earnedTokens;
                tokenType = `${this.stakingData.TOKEN2} Tokens`;
                break;
            default:
        }

        return (
            <div>
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        <div className="confirm-amount">
                            {amount} <small>{tokenType}</small>
                            {amount2 ?
                                <>
                                    {' '} <small>and</small> {amount2} <small>{tokenType2}</small>
                                </>
                                : ''}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    renderAwaitConfirmation() {
        let content;
        switch (this.state.modalContentType) {
            case STAKE_ACTION_TYPE.APPROVE:
                content = `Approving`;
                break;
            case STAKE_ACTION_TYPE.DEPOSIT:
                content = `Depositing`;
                break;
            case STAKE_ACTION_TYPE.WITHDRAW:
                content = `Withdrawing`;
                break;
            case STAKE_ACTION_TYPE.CLAIM:
                content = `Claiming`;
                break;
            default:
                content = `Approving`;
        }

        return (
            <div>
                <Row className="p-0 m-0">
                    <Col className="d-flex justify-content-center">
                        Waiting for Confirmation
                    </Col>
                </Row>
                <Row className="p-0 m-0">
                    <Col className="d-flex justify-content-center">
                        <span><strong><small>{content}</small></strong></span>
                    </Col>
                </Row>
                <Row className="p-0 mt-1 mx-0">
                    <Col className="d-flex justify-content-center">
                        <span className="confirm-transaction-text">Confirm this transaction in your wallet</span>
                    </Col>
                </Row>
            </div>
        );
    }

    renderTransactionComplete(modalContentType) {
        let content;

        switch (modalContentType) {
            case STAKE_ACTION_TYPE.APPROVE:
                content = `Approved!`;
                break;
            case STAKE_ACTION_TYPE.DEPOSIT:
                content = `Deposited!`;
                break;
            case STAKE_ACTION_TYPE.WITHDRAW:
                content = `Withdrawn!`;
                break;
            case STAKE_ACTION_TYPE.CLAIM:
                content = `Claimed!`;
                break;
            default:
                content = `Approved!`;
        }
        return (
            <div>
                <Row className="p-0 mx-0 mb-2 d-flex justify-content-center">
                    <Col xs={12} className="d-flex justify-content-center px-2 m-0">
                        <FontAwesomeIcon icon={faCheckCircle} className={'text-success'} size="9x"/>
                    </Col>
                </Row>
                <Row className="p-0 m-0">
                    <Col className="d-flex justify-content-center p-0 m-0">
                        {content}
                    </Col>
                </Row>
                {
                    this.state.txUrl !== "" &&
                    <Row className="p-0 m-0">
                        <Col className="d-flex justify-content-center p-0 m-0">
                          <span>
                            <strong><small>
                              <a href={this.state.txUrl} className="scan-link" target="_blank"
                                 rel="noopener noreferrer">View on {this.stakingData.EXPLORER}</a>
                            </small></strong>
                          </span>
                        </Col>
                    </Row>
                }

            </div>
        );
    };

    renderGeneralStakingInfo() {
        return <Row>
            <Col xs={12} md={6}>
                <Box
                    boxWidth={100}
                    boxHeight={50}
                    boxStyle={'style-4'}
                    rounded={'rounded-2'}
                    className='p-3 mb-2'
                >
                    <div className="liquidity-title mb-3 text-uppercase">Total Deposits</div>
                    <div className="liquidity-amount text-center mx-1">
                        {numeral(this.totalDeposits).format('0,0.0000')}
                    </div>
                    <div className="liquidity-type mt-1">{this.stakingData.STAKE_TYPE}</div>
                </Box>
            </Col>
            {/*<Col xs={12} md={6}>*/}
            {/*    <Box*/}
            {/*        boxWidth={100}*/}
            {/*        boxHeight={50}*/}
            {/*        boxStyle={'style-4'}*/}
            {/*        rounded={'rounded-2'}*/}
            {/*        className='p-3 mb-2'*/}
            {/*    >*/}
            {/*        <div className="liquidity-title mb-3 text-uppercase">APR</div>*/}
            {/*        <div className="liquidity-amount text-center">*/}
            {/*            {numeral(this.APR).format('0,0.0000') ?? 0} %*/}
            {/*        </div>*/}
            {/*        <div className="liquidity-type mt-1">&nbsp;</div>*/}
            {/*    </Box>*/}
            {/*</Col>*/}
            <Col xs={12} md={6}>
                <Box
                    boxWidth={100}
                    boxHeight={50}
                    boxStyle={'style-4'}
                    rounded={'rounded-2'}
                    className='p-3 mb-2'
                >
                    <div className="liquidity-title mb-3 text-uppercase">Reward rate</div>
                    <div className="liquidity-amount text-center">
                        ⚡ {numeral(this.rewardRate).format('0,0.0000') ?? 0}
                    </div>
                    <div className="liquidity-type mt-1">PER BLOCK</div>
                </Box>
            </Col>
        </Row>
    }

    renderBalanceInfo() {
        return <>
            <Col xs={12} md={12} lg={6}>
                <Row>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">{this.stakingData.STAKE_TYPE} Tokens
                            </div>
                            <div className="liquidity-amount text-center">
                                <span
                                    className={'m-0 p-0 d-none d-lg-block'}>{numeral(this.stakeTokenBal).format('0,0.0000', Math.floor)}</span>
                                <span
                                    className={'m-0 p-0 d-block d-lg-none'}>{this.stakeTokenBal}</span>
                                <small
                                    className={'mt-1 small-full-balance m-0 p-0 text-muted d-none d-lg-block'
                                    + (showFullBalance(this.stakeTokenBal) ? '' : 'd-none')}
                                >
                                    ({this.stakeTokenBal})
                                </small>
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={'get-reward-btn'}
                                    text={`ADD MORE`}
                                    handleClick={async () => {
                                        window.open(await this.stakingRewardsContract.getSwapUrl(), '_blank');
                                    }}
                                    className='btn btn-secondary btn-block blue btn-sm ml-2 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                    </Col>
                    {this.stakingData.STAKE_TYPE !== this.stakingData.REWARD_TYPE && <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">{this.stakingData.REWARD_TYPE} Tokens
                            </div>
                            <div
                                className="liquidity-amount text-center line-height-1">
                                <span
                                    className={'m-0 p-0 d-none d-lg-block'}>{numeral(this.rewardTokenBal).format('0,0.0000', Math.floor)}</span>
                                <span
                                    className={'m-0 p-0 d-block d-lg-none'}>{this.rewardTokenBal}</span>
                                <small
                                    className={'mt-1 small-full-balance m-0 p-0 text-muted d-none d-lg-block'
                                    + (showFullBalance(this.rewardTokenBal) ? '' : 'd-none')}
                                >
                                    ({this.rewardTokenBal})
                                </small>
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={`get-${this.stakingData.STAKE_TYPE}-btn`}
                                    text={`ADD MORE`}
                                    handleClick={() => this.openSwapUrl('add')}
                                    className='btn btn-secondary blue btn-sm ml-2 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                    </Col>}
                </Row>
            </Col>
        </>;
    }

    renderStakingInfo() {
        return <>
            <Col xs={12} md={12} lg={6}>
                <Row>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div
                                className="liquidity-title mb-3 text-uppercase">Your {this.stakingData.STAKE_TYPE} deposits
                            </div>
                            <div className="liquidity-amount text-center">
                                {numeral(this.stakedTokens).format('0,0.0000')}
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={'get-reward-btn'}
                                    text={`STAKE TOKENS`}
                                    handleClick={this.toggleStakeForm}
                                    disabled={this.state.canStake && !this.isDepositDisabled() ? '' : 'disabled'}
                                    className='btn btn-secondary blue btn-sm ml-2 w-40 liquidity-button'
                                    display={'d-inline-block'}
                                    buttonHeight={'button-height-2'}
                                    buttonSize={'size-2'}
                                />
                            </div>
                        </Box>
                        <Collapse in={this.state.isStakeFormOpen}>
                            <Box
                                boxWidth={100}
                                boxHeight={50}
                                boxStyle={'style-3'}
                                rounded={'rounded-2'}
                                className='p-3 my-2'
                            >
                                <Button type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={this.toggleStakeForm}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </Button>
                                <Form>
                                    <Form.Group controlId="amountToStake">
                                        <Form.Label>
                                            Enter amount to stake
                                        </Form.Label>

                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                min="0.00"
                                                placeholder="0.00"
                                                value={this.state.depositAmount}
                                                onFocus={this.handleInputFocus.bind(this)}
                                                onBlur={this.handleInputBlur.bind(this)}
                                                onChange={this.handleInputChange.bind(this)}
                                                disabled={this.state.isInputAmountDisabled || this.isDepositDisabled() ? 'disabled' : ''}
                                                name="depositAmount"
                                            />
                                            <InputGroup.Prepend>
                                                <Button
                                                    id="max-btn"
                                                    onClick={this.handleMax}
                                                    disabled={this.state.isInputAmountDisabled ? 'disabled' : ''}
                                                    className='btn blue'
                                                >
                                                    Max
                                                </Button>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    </Form.Group>
                                    <ButtonToolbar className='w-100 row mx-auto'>
                                        <ButtonGroup aria-label="Stake controls"
                                                     className='col-6 col-md-6 px-1 mb-2'>
                                            <CustomButton
                                                id="approve-btn"
                                                text={this.state.approveButtonText}
                                                handleClick={() => this.handleOpenConfirmModal(STAKE_ACTION_TYPE.APPROVE)}
                                                disabled={this.state.isApproveDisabled || this.isDepositDisabled() ? 'disabled' : ''}
                                                isLoading={this.state.isApproveLoading}
                                                className='btn btn-secondary btn-block blue btn-sm'
                                            />
                                        </ButtonGroup>
                                        <ButtonGroup aria-label="Stake controls"
                                                     className='col-6 col-md-6 px-1 mb-2'>
                                            <CustomButton
                                                id={'deposit-tokens-btn'}
                                                text={this.state.depositButtonText}
                                                disabled={!this.state.canDeposit || this.isDepositDisabled() ? 'disabled' : ''}
                                                handleClick={() => this.handleOpenConfirmModal(STAKE_ACTION_TYPE.DEPOSIT)}
                                                isLoading={this.state.isDepositLoading}
                                                className='btn btn-secondary btn-block blue btn-sm'
                                            />
                                        </ButtonGroup>

                                        <div className={'text-center'}><small> Max
                                            stake-able: {this.maxStake - this.stakedTokens} | Approved
                                            tokens: {this.state.approvedTokens}</small></div>
                                    </ButtonToolbar>
                                </Form>
                            </Box>
                        </Collapse>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">Your Rate</div>
                            <div className="liquidity-amount text-center">
                                {numeral((this.rewardRate) * (this.stakedTokens / this.totalDeposits)).format('0,0.0000')}
                            </div>
                            <div className="liquidity-type mt-3">{this.stakingData.REWARD_TYPE} / block</div>
                        </Box>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <Box
                            boxWidth={100}
                            boxHeight={50}
                            boxStyle={'style-4'}
                            rounded={'rounded-2'}
                            className='p-3 mb-2'
                        >
                            <div className="liquidity-title mb-3 text-uppercase">{this.stakingData.TOKEN2} Token
                                Earnings
                            </div>
                            <div className="liquidity-amount text-center">
                                {numeral(this.earnedTokens).format('0,0.0000')}
                            </div>
                            <div className="liquidity-type mt-3">
                                <CustomButton
                                    id={'claim-btn'}
                                    className='btn btn-secondary blue btn-sm ml-2 liquidity-button'
                                    text={'WITHDRAW'}
                                    handleClick={this.toggleWithDrawForm.bind(this)}
                                    display={'d-inline-block'}
                                    disabled={this.state.isWithdrawDisabled || this.isWithdrawDisabled() ? 'disabled' : ''}
                                />
                            </div>
                        </Box>

                        <Collapse in={this.state.isWithDrawFormOpen}>
                            <Box
                                boxWidth={100}
                                boxHeight={50}
                                boxStyle={'style-3'}
                                rounded={'rounded-2'}
                                className='p-3 my-2'
                            >
                                <Button type="button"
                                        className="close mb-2"
                                        aria-label="Close"
                                        onClick={() => this.toggleWithDrawForm()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </Button>
                                <Form>
                                    <ButtonToolbar className='w-100 row mx-auto'>
                                        <ButtonGroup aria-label="Stake controls"
                                                     className='col-12 col-md-6 px-1 mb-2'>
                                            <CustomButton
                                                id={'claim-btn'}
                                                className='btn btn-secondary blue btn-sm liquidity-button text-uppercase'
                                                text={this.state.claimButtonText + ' Earnings'}
                                                handleClick={() => this.handleOpenConfirmModal(STAKE_ACTION_TYPE.CLAIM)}
                                                display={'d-inline-block'}
                                                disabled={(this.state.isClaimDisabled || this.earnedTokens === 0) || this.isWithdrawDisabled() ? 'disabled' : ''}
                                                isLoading={this.state.isClaimLoading}
                                            />
                                        </ButtonGroup>
                                        <ButtonGroup aria-label="Stake controls"
                                                     className='col-12 col-md-6 px-1 mb-2'>
                                            <CustomButton
                                                id={'reward-btn'}
                                                text={this.state.withdrawButtonText + ' & Claim'}
                                                disabled={this.state.isWithdrawDisabled || this.isWithdrawDisabled() ? 'disabled' : ''}
                                                handleClick={() => this.handleOpenConfirmModal(STAKE_ACTION_TYPE.WITHDRAW)}
                                                isLoading={this.state.isWithdrawLoading}
                                                className='btn btn-secondary btn-block blue btn-sm text-uppercase'
                                            />
                                        </ButtonGroup>
                                        <div className="claim-note text-center mx-auto mt-2">
                                            Note: Withdrawing will automatically claim your liquidity
                                            deposits on your
                                            behalf
                                        </div>
                                    </ButtonToolbar>
                                </Form>
                            </Box>
                        </Collapse>
                    </Col>
                </Row>
            </Col>
        </>
    }

    isWithinLimits(toStake) {
        return (toStake + this.stakedTokens) <= this.maxStake;
    }

    handleMax() {
        let toStake;
        if (!this.isWithinLimits(this.stakeTokenBal)) {
            console.log(1)
            toStake = this.maxStake - this.stakedTokens;
        } else {
            console.log(2)
            toStake = this.stakeTokenBal
        }
        this.updateDepositAmount(toStake + '');
    }

    render() {
        if (!this.stakingData) return <Redirect to="/404"/>;
        if (!this.stakingData.IS_ACTIVE) return <Redirect to="/404"/>;

        const token1 = getTokenIcon(this.stakingData.TOKEN1);
        const token2 = getTokenIcon(this.stakingData.TOKEN2);

        let networkInfo = '';
        if (this.isConnected && this.stakingData.SUPPORTED_NETWORK_ID !== this.chainId) {
            networkInfo = this.getNetworkInfoMessage();
        }

        let subHeadingTxt = <>{this.stakingData.STAKE_TYPE} Pool {networkInfo}</>;
        let hasEndedTxt = this.hasEnded ? ' (Ended)' : '';

        return (
            <div className="staking-actions">
                {this.renderModalDialog()}
                <Notice category={this.category}/>
                <Row className="py-0 mt-5 mb-3 mb-md-3">
                    <Col xs={12} lg={12} className="">
                        <Heading
                            heading={this.stakingData.TITLE + hasEndedTxt}
                            headingSize={'heading-2'}
                            headingColor={'#3cbbe8'}
                            subheading={subHeadingTxt}
                            // subheadingSize={'subheading-1'}
                            subheadingColor={'#fff'}
                            token1={token1}
                            token2={token2}
                        />
                    </Col>
                </Row>
                {this.renderGeneralStakingInfo()}
                <Row className="px-4 px-md-1 my-5 my-md-5 mx-0 lighter-blue mx-n5">
                    <Col xs={12} className="p-4 px-md-5 m-0">
                        <Heading
                            heading={'Account info'}
                            headingSize={'heading-2'}
                            headingColor={'#3cbbe8'}
                            subheading={`STAKING, BALANCES & EARNINGS`}
                            subheadingColor={'#fff'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Container fluid>
                        <Row>
                            {this.renderBalanceInfo()}
                            {this.renderStakingInfo()}
                        </Row>

                    </Container>
                </Row>
            </div>
        )
    }
}

export default PoolActionsPage;
