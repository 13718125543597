import {poolStakingV1List} from "./poolStakingV1List";
import {poolStakingV2List} from "./poolStakingV2List";
import KgoSrkbPool from "./pairs/KgoSrkbPool";
import SrkbKgoPool from "./pairs/SrkbKgoPool";
import SrkbSfuelPool from "./pairs/SrkbSfuelPool";
import SfuelSfuelPool from "./pairs/SfuelSfuelPool";
import SrkbSrkbPool from "./pairs/SrkbSrkbPool";
import SrkbBcpPool from "./pairs/SrkbBcpPool";
import BcpSfuelPool from "./pairs/BcpSfuelPool";
import SrkbAirxPool from "./pairs/SrkbAirxPool";
import SrkbFigPool from "./pairs/SrkbFigPool";
import SrkbOwnPool from "./pairs/SrkbOwnPool";
import ownOwnPool from "./pairs/ownOwnPool";
import SrkbKclpPool from "./pairs/SrkbKclpPool";
import KclpSrkbPool from "./pairs/KclpSrkbPool";

let lStaking = {};

export const initBscLaunchPoolContracts = async (tokens) => {

    poolStakingV1List.list['SRKb-SFUEL'].map((address, index) => {
        lStaking['srkbSfuelLaunchPoolV' + (index + 1)] = new SrkbSfuelPool(
            {
                address: address,
                abi: poolStakingV1List.abi
            }, tokens.srkbToken);
    })

    poolStakingV1List.list['SFUEL-SFUEL'].map((address, index) => {
        lStaking['sfuelSfuelLaunchPoolV' + (index + 1)] = new SfuelSfuelPool(
            {
                address: address,
                abi: poolStakingV1List.abi
            }, tokens.sfuelToken);
    })

    poolStakingV1List.list['SRKb-SRKb'].map((address, index) => {
        lStaking['srkbSrkbLaunchPoolV' + (index + 1)] = new SrkbSrkbPool(
            {
                address: address,
                abi: poolStakingV1List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['SRKb-OWN'].map((address, index) => {
        lStaking['srkbOwnLaunchPoolV' + (index + 1)] = new SrkbOwnPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['OWN-OWN'].map((address, index) => {
        lStaking['ownOwnLaunchPoolV' + (index + 1)] = new ownOwnPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.ownToken);
    })

    poolStakingV2List.list['SRKb-BCP'].map((address, index) => {
        lStaking['srkbBcpLaunchPoolV' + (index + 1)] = new SrkbBcpPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['BCP-SFUEL'].map((address, index) => {
        lStaking['bcpSfuelLaunchPoolV' + (index + 1)] = new BcpSfuelPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.bcpToken);
    })
    poolStakingV2List.list['SRKb-AIRx'].map((address, index) => {
        lStaking['srkbAirxLaunchPoolV' + (index + 1)] = new SrkbAirxPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['SRKb-FIG'].map((address, index) => {
        lStaking['srkbFigLaunchPoolV' + (index + 1)] = new SrkbFigPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['KGO-SRKb'].map((address, index) => {
        lStaking['kgoSrkbLaunchPoolV' + (index + 1)] = new KgoSrkbPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.kgoToken);
    })
    poolStakingV2List.list['SRKb-KGO'].map((address, index) => {
        lStaking['srkbKgoLaunchPoolV' + (index + 1)] = new SrkbKgoPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['SRKb-KCLP'].map((address, index) => {
        lStaking['srkbKclpLaunchPoolV' + (index + 1)] = new SrkbKclpPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.srkbToken);
    })
    poolStakingV2List.list['KCLP-SRKb'].map((address, index) => {
        lStaking['kclpSrkbLaunchPoolV' + (index + 1)] = new KclpSrkbPool(
            {
                address: address,
                abi: poolStakingV2List.abi
            }, tokens.kclpToken);
    })

    return lStaking
};
