import Token from "../../eth/tokens/Token";
import {
    AIRx_ABI,
    AIRx_BNB_SPARK_ABI,
    BCP_ABI,
    BCP_BNB_SPARK_ABI,
    BSRK_ABI,
    BSRK_BNB_CAKE_ABI,
    BUSD_ABI,
    CHIMOM_ABI,
    CHIMOM_BNB_SPARK_ABI,
    FIG_ABI,
    FIG_BNB_SPARK_ABI,
    SFUEL_ABI,
    SFUEL_BNB_CAKE_ABI,
    SRKB_ABI,
    SRKB_BNB_SPARK_ABI,
    SRKB_BUSD_SPARK_ABI,
    KGO_ABI,
    KGO_BNB_SPARK_ABI, OWN_ABI, OWN_BNB_SPARK_ABI, KCLP_ABI,
    KCLP_BUSD_CAKE_ABI, KCLP_BUSD_SPARK_ABI
} from "./abis";
import {CakePair, SparkPair} from "./lp";
import {AIRx, BCP, BSRK, BUSD, CHIMOM, FIG, KCLP, KGO, OWN, SFUEL, SRKb} from "./constants";

export const initBscTokenContracts = async () => {
    let tokens = {};
    tokens.chimomToken = new Token(CHIMOM_ABI);
    tokens.chimomBnbSparkLPToken = new SparkPair(CHIMOM_BNB_SPARK_ABI, CHIMOM);

    tokens.sfuelToken = new Token(SFUEL_ABI);
    tokens.sfuelBnbCakeLpToken = new CakePair(SFUEL_BNB_CAKE_ABI, SFUEL);

    tokens.bsrkToken = new Token(BSRK_ABI);
    tokens.bsrkbnbCakeLPToken = new CakePair(BSRK_BNB_CAKE_ABI, BSRK);

    tokens.srkbToken = new Token(SRKB_ABI);
    tokens.srkbBnbSparkLPToken = new SparkPair(SRKB_BNB_SPARK_ABI, SRKb);

    tokens.busdToken = new Token(BUSD_ABI);
    tokens.srkbBusdSparkLPToken = new SparkPair(SRKB_BUSD_SPARK_ABI, SRKb, BUSD);

    tokens.bcpToken = new Token(BCP_ABI);
    tokens.bcpBnbSparkLPToken = new SparkPair(BCP_BNB_SPARK_ABI, BCP);

    tokens.airxToken = new Token(AIRx_ABI);
    tokens.airxBnbSparkLPToken = new SparkPair(AIRx_BNB_SPARK_ABI, AIRx);

    tokens.figToken = new Token(FIG_ABI);
    tokens.figBnbSparkLPToken = new SparkPair(FIG_BNB_SPARK_ABI, FIG);

    tokens.kgoToken = new Token(KGO_ABI);
    tokens.kgoBnbSparkLPToken = new SparkPair(KGO_BNB_SPARK_ABI, KGO);

    tokens.ownToken = new Token(OWN_ABI);
    tokens.ownBnbSparkLPToken = new SparkPair(OWN_BNB_SPARK_ABI, OWN);

    tokens.kclpToken = new Token(KCLP_ABI);
    tokens.kclpBusdCakeLPToken = new SparkPair(KCLP_BUSD_CAKE_ABI, KCLP, BUSD);
    tokens.kclpBusdSparkLPToken = new SparkPair(KCLP_BUSD_SPARK_ABI, KCLP, BUSD);
    return {
        ...tokens
    }
}
