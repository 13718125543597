import _BcpBnbStakeActionsPage from "./BcpBnbStakeActionsPage";
import _BsrkBnbStakeActionsPage from "./BsrkBnbStakeActionsPage";
import _ChimomBnbStakeActionsPage from "./ChimomBnbStakeActionsPage";
import _SfuelBnbStakeActionsPage from "./SfuelBnbStakeActionsPage";
import _SrkbBnbStakeActionsPage from "./SrkbBnbStakeActionsPage";
import _SrkbBusdStakeActionsPage from "./SrkbBusdStakeActionsPage";
import _AirxBnbStakeActionsPage from "./AirxBnbStakeActionsPage";
import _FigBnbStakeActionsPage from "./FigBnbStakeActionsPage";
import _KgoBnbStakeActionsPage from "./KgoBnbStakeActionsPage";
import _OwnBnbStakeActionsPage from "./OwnBnbStakeActionsPage";
import _KclpBusdStakeActionsPage from "./KclpBusdStakeActionsPage";
import _KclpBusdSfuelStakeActionsPage from "./KclpBusdSfuelStakeActionsPage";

export const AirxBnbStakeActionsPage = _AirxBnbStakeActionsPage
export const BcpBnbStakeActionsPage = _BcpBnbStakeActionsPage
export const BsrkBnbStakeActionsPage = _BsrkBnbStakeActionsPage
export const ChimomBnbStakeActionsPage = _ChimomBnbStakeActionsPage
export const SfuelBnbStakeActionsPage = _SfuelBnbStakeActionsPage
export const SrkbBnbStakeActionsPage = _SrkbBnbStakeActionsPage
export const SrkbBusdStakeActionsPage = _SrkbBusdStakeActionsPage
export const FigBnbStakeActionsPage = _FigBnbStakeActionsPage
export const KgoBnbStakeActionsPage = _KgoBnbStakeActionsPage
export const OwnBnbStakeActionsPage = _OwnBnbStakeActionsPage
export const KclpBusdStakeActionsPage = _KclpBusdStakeActionsPage
export const KclpBusdSfuelStakeActionsPage = _KclpBusdSfuelStakeActionsPage
