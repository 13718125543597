export const ETH_LP_STAKING = {
    list: {
        srkEthPoolV1: {},
        srkEthPoolV2: {},
        srkEthPoolV3: {},
        srkUsdtPool: {},
    },
    contracts: {
        // SRK-ETH POOL
        srkEthStakingRewardsV1: null,
        srkEthStakingRewardsV2: null,
        srkEthStakingRewardsV3: null,
        // SRK-USDT POOL
        srkUsdtStakingRewards: null,
    }
}
