import UniV2Pair from "../../../eth/tokens/lp/UniV2Pair";
import {SRKb, WBNB} from "../../../constants";

class CakePair extends UniV2Pair {
    constructor(contractData, token0 = SRKb, token1 = WBNB) {
        super();
        if (this.initialized) {
            return;
        }
        this.contractData = contractData;
        this._token0 = token0;
        this._token1 = token1;
    }

    /* Not Supported */
    async permit(owner, spender, value) {
        return false;
    }
}

export default CakePair;
