import {BINANCE_SMART_CHAIN} from "./bsc/constants";
import {onWrongNetwork} from "./helpers";
import {initEthTokenContracts} from "./eth/tokens/init";
import {initBscLiquidityStakingContracts} from "./bsc/staking/liquidity/init";
import {initEthLiquidityStakingContracts} from "./eth/staking/liquidity";

import {initBscLaunchPoolContracts} from "./bsc/staking/pool/init";
import {initBscTokenContracts} from "./bsc/tokens/init";

let contracts = {
    tokens: {},
    lpStaking: {},
    lStaking: {},
};

export const initializeContracts = async (blockchain, section = 'liquidity') => {
    try {
        switch (section) {
            case 'swap':
            default:
                if (blockchain === BINANCE_SMART_CHAIN) {
                    contracts.tokens = await initBscTokenContracts();
                    contracts.lpStaking = await initBscLiquidityStakingContracts(contracts.tokens);
                    contracts.lStaking = await initBscLaunchPoolContracts(contracts.tokens);
                } else {
                    contracts.tokens = await initEthTokenContracts();
                    contracts.lpStaking = await initEthLiquidityStakingContracts(contracts.tokens);
                }
        }
    } catch (e) {
        onWrongNetwork();
    }

    return {
        ...contracts.tokens,
        ...contracts.lpStaking,
        ...contracts.lStaking,
    };
}
