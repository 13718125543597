import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class BsrkBnbStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.bsrk;
        this.lpTokenBal = props.wallet.balance.bsrkbnbCakeLP;

        this.lpTokenContract = props.contracts.bsrkbnbCakeLPToken;
        this.stakingRewardsContract = props.contracts.bsrkBnbStakingRewards;

        this.poolData = props.staking.bsrkBnbPool;
    }
}

export default withRouter(BsrkBnbStakeActionsPage);
