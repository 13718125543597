

export const updateBscAccountBalances = async (
    selectedWalletAddress,
    contracts
) => {
    let balances = {};
    const busd = await contracts.busdToken.balanceOf(selectedWalletAddress, true);
    const sfuel = await contracts.sfuelToken.balanceOf(selectedWalletAddress, true);
    const cakeLp = await contracts.sfuelBnbCakeLpToken.balanceOf(selectedWalletAddress, true);

    balances.busd = parseFloat(busd.toSignificant(15));
    balances.sfuel = parseFloat(sfuel.toSignificant(15));
    balances.sfuelBnbCakeLP = parseFloat(cakeLp.toSignificant(15));

    // BSRK-BNB
    const bsrk = await contracts.bsrkToken.balanceOf(selectedWalletAddress, true);
    const bsrkbnbCakeLP = await contracts.bsrkbnbCakeLPToken.balanceOf(selectedWalletAddress, true);
    balances.bsrk = parseFloat(bsrk.toSignificant(15));
    balances.bsrkbnbCakeLP = parseFloat(bsrkbnbCakeLP.toSignificant(15));

    const srkb = await contracts.srkbToken.balanceOf(selectedWalletAddress, true);
    balances.srkb = parseFloat(srkb.toSignificant(15));
    const srkbBnbSparkLP = await contracts.srkbBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.srkbBnbSparkLP = parseFloat(srkbBnbSparkLP.toSignificant(15));
    const srkbBusdSparkLP = await contracts.srkbBusdSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.srkbBusdSparkLP = parseFloat(srkbBusdSparkLP.toSignificant(15));

    const chimom = await contracts.chimomToken.balanceOf(selectedWalletAddress, true);
    const chimomBnbSparkLP = await contracts.chimomBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.chimom = parseFloat(chimom.toSignificant(15));
    balances.chimomBnbSparkLP = parseFloat(chimomBnbSparkLP.toSignificant(15));

    const bcp = await contracts.bcpToken.balanceOf(selectedWalletAddress, true);
    const bcpBnbSparkLP = await contracts.bcpBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.bcp = parseFloat(bcp.toSignificant(15));
    balances.bcpBnbSparkLP = parseFloat(bcpBnbSparkLP.toSignificant(15));

    const airx = await contracts.airxToken.balanceOf(selectedWalletAddress, true);
    const airxBnbSparkLP = await contracts.airxBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.airx = parseFloat(airx.toSignificant(15));
    balances.airxBnbSparkLP = parseFloat(airxBnbSparkLP.toSignificant(15));

    const fig = await contracts.figToken.balanceOf(selectedWalletAddress, true);
    const figBnbSparkLP = await contracts.figBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.fig = parseFloat(fig.toSignificant(15));
    balances.figBnbSparkLP = parseFloat(figBnbSparkLP.toSignificant(15));

    const kgo = await contracts.kgoToken.balanceOf(selectedWalletAddress, true);
    const kgoBnbSparkLP = await contracts.kgoBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.kgo = parseFloat(kgo.toSignificant(15));
    balances.kgoBnbSparkLP = parseFloat(kgoBnbSparkLP.toSignificant(15));

    const own = await contracts.ownToken.balanceOf(selectedWalletAddress, true);
    const ownBnbSparkLP = await contracts.ownBnbSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.own = parseFloat(own.toSignificant(15));
    balances.ownBnbSparkLP = parseFloat(ownBnbSparkLP.toSignificant(15));

    const kclp = await contracts.kclpToken.balanceOf(selectedWalletAddress, true);
    balances.kclp = parseFloat(kclp.toSignificant(15));
    const kclpBusdCakeLP = await contracts.kclpBusdCakeLPToken.balanceOf(selectedWalletAddress, true);
    balances.kclpBusdCakeLP = parseFloat(kclpBusdCakeLP.toSignificant(15));
    const kclpBusdSparkLP = await contracts.kclpBusdSparkLPToken.balanceOf(selectedWalletAddress, true);
    balances.kclpBusdSparkLP = parseFloat(kclpBusdSparkLP.toSignificant(15));

    return balances;
};
