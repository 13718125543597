import {withRouter} from 'react-router-dom';
import BscStakeActionsPage from "./BscStakeActionsPage";

class SrkbBusdStakeActionsPage extends BscStakeActionsPage {
    constructor(props) {
        super(props);
        this.rewardsTokenBal = props.wallet.balance.srkb;
        this.lpTokenBal = props.wallet.balance.srkbBusdSparkLP;

        this.lpTokenContract = props.contracts.srkbBusdSparkLPToken;
        this.stakingRewardsContract = props.contracts.srkbBusdStakingRewards;

        this.poolData = props.staking.srkbBusdPool;
    }
}

export default withRouter(SrkbBusdStakeActionsPage);
