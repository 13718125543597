import {BCP, REACT_APP_SPARKSWAP_URL, SFUEL} from '../../../../constants';
import PoolBasedStakingV2 from "../PoolBasedStakingV2";
import {buildTxUrl, expandValue} from "../../../../helpers";
import Web3Instance from "../../../../Web3Instance";
import {TokenAmount} from "@uniswap/sdk";

class BcpSfuelPool extends PoolBasedStakingV2 {
    initialized = false;

    constructor(contractData, stakeTokenInstance) {
        super(contractData, {
            stakeTokenInstance: stakeTokenInstance,
            rewardsToken: SFUEL,
            stakingToken: BCP,
            pairToken: 'ETH',
            dexUrl: REACT_APP_SPARKSWAP_URL,
            rewardTokenDexUrl: 'https://sparkswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xB44a9B6905aF7c801311e8F4E76932ee959c663C'
        });
    }

    async getMaxStake() {
        return (await this.contract.methods.maxStaking().call()) / Math.pow(10, 8);
    }

    async stake(address, amount) {

        amount = expandValue(this.web3, amount, 8)

        let txData = await this.contract.methods.enterStaking(amount.toString()).send({from: address});

        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }

    async getTotalDeposits(toDecimal = false, toTokenAmount = false) {
        let balance = await this.contract.methods.totalDeposit().call();
        if (toDecimal) {
            balance = balance / Math.pow(10, 8);
        }
        let tokenAmount = new TokenAmount(this.stakingToken, balance);

        if (toTokenAmount) {
            return tokenAmount;
        }

        return tokenAmount.toSignificant(15);
    }

    async withdraw(address, amount) {
        amount = expandValue(this.web3, amount, 8)

        let txData = await this.contract.methods.leaveStaking(amount.toString()).send({from: address});
        txData.txUrl = buildTxUrl(txData.transactionHash, Web3Instance.chainId);

        return txData;
    }
}

export default BcpSfuelPool;
