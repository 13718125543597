import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading';

const P2PLendingPage = () => {
  return (
    <div className="p2p-lending-page">
      <Row className="px-3 py-0 mx-0 mx-md-5 mt-5 mb-4 mb-md-5">
        <Col xs={12} className="p-0 m-0">
          <Heading heading={'P2P Lending (Coming Soon)'} />
        </Col>
      </Row>
    </div>
  );
}

export default P2PLendingPage;
